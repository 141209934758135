import { useEffect, useMemo, useState, useCallback } from "react";
import AuthContext from "./authContext";
import { CookieHelper } from '@utils/cookieHelper'

export const initialLoginState: AuthStateType = {
  userId: "",
  name: "",
  email: "",
  wanDbId: "",
  token: "",
};

const AuthState = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const [state, setState] = useState<AuthStateType>(initialLoginState);
  const { setItem, getItem, removeItem } = CookieHelper();

  const login = useCallback((data: AuthStateType) => {
    setItem('token', data?.token ?? '');
    setItem('userData', JSON.stringify(data));
    setState(data)
  }, [setItem]);

  const logout = useCallback(() => {
    removeItem('token')
    removeItem('userData');
    setState(initialLoginState);
  }, [removeItem]);

  useEffect(() => {
    const userData = getItem("userData") || "";
    const parsedUserData = userData ? JSON.parse(userData) : null;

    if (parsedUserData) {
      setState({
        userId: parsedUserData?.userId || "",
        name: parsedUserData?.name || "",
        email: parsedUserData?.email || "",
        wanDbId: parsedUserData?.wanDbId || "",
        token: parsedUserData?.token || "",
      });
    }
  }, [getItem]);

  const stateValue = useMemo(() => ({ state, setState, login, logout }), [state, setState, login, logout]);

  return (
    <AuthContext.Provider value={stateValue}> {children}</AuthContext.Provider>
  );
};

export default AuthState;
