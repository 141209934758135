
// material-ui
import {
  FormHelperText,
  Grid,
  Stack
} from '@mui/material';

// custom ui
import { ErrorHelper as ErrorHelperWrapper, CustomEmailField } from '@components/shared-ui-components/CustomFields';
import { CustomSubmitButton, CustomLinkButton } from '@components/shared-ui-components/Buttons'

// third party

const AuthForgetPassword = (formik: any) => {

  const { errors, handleBlur, handleChange, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}

  return (
    <form noValidate onSubmit={handleSub}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomEmailField
            id="email-login"
            value={values.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.email && errors.email)}
            label="Email Address"
          />
          <ErrorHelperWrapper isError={!!(touched.email && errors.email)} errorMsg={errors.email} helperId='email-register' />
        </Grid>

        {errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{errors.submit.toString()}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomSubmitButton btnName="Send Reset Link" isSubmitting={isSubmitting} />
        </Grid>
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <CustomLinkButton linkTo='/auth/login' label='Back to login' />
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthForgetPassword;
