import { createContext } from "react";

interface initialStateType {
  state: AuthStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setState: any;
  login: any,
  logout: any,
}

const initialAuthContextValue = {
  state: {
    userId: '',
    name: '',
    email: '',
    wanDbId: '',
    token: '',
  },
  setState: () => { },
  login: () => { },
  logout: () => { }
}

const AuthContext = createContext<initialStateType>(initialAuthContextValue);

export default AuthContext;
