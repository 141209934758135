import { Typography } from "@mui/material"
import { Link } from 'react-router-dom';
import React, { CSSProperties } from 'react';

interface ILinkButton {
    label: string,
    linkTo: string,
    style?: CSSProperties
}

function CustomLinkButton(props: ILinkButton) {
    const { label, linkTo, style } = props;
    return (
        <Typography component={Link} to={linkTo} variant="body1" sx={{ textDecoration: 'none', ...style }} color="primary">
            {label}
        </Typography>
    )
}

export default CustomLinkButton;