import { useContext, useState } from 'react'
import { FormikHelpers, useFormik } from 'formik'
// import { callDynamicFormApi, dynamicFormValidationSchema, getDynamicFormInitialValues, renderFormFields } from './helper'
import ProjectService from '@services/projectService';
import { getProjectFormInitialValues, getActionAfterSubmit, projectFormValidationSchema } from './helper';
import CreateProjectForm from '@pages/ProjectsPage/steps/createProjectStep';
import UploadUnlabelledSetForm from '@pages/ProjectsPage/steps/uploadUnlabelledStep';
import UploadLabelledSetForm from '@pages/ProjectsPage/steps/uploadLabelledStep';
import ProjectContext from '@context/Project/projectContext';
import StepsEventsProgressbar from '../StepsEventsProgressbar';
import { Box, Button, Dialog, DialogActions } from '@mui/material';
import { getStepsProgressbarApiEndpoint } from '../DynamicForm/helper';

interface ProjectFormProps {
  page: string,
  activeStepIndex: number,
  handleAction: any,
  handleNext?: any,
  handleBack?: any
  handleFieldChange?: (fieldName: string, fieldValue: any) => void
}

function ProjectForm({ page, handleAction, handleNext }: ProjectFormProps) {

  const projectContextInfo = useContext(ProjectContext)
  const [dialog, setDialog] = useState(false)
  const [apiEndpoint, setApiEndpoint] = useState('')

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    // Handle form submission

    // Over here we will get api function to be call
    const actionAfterCall = getActionAfterSubmit(page, projectContextInfo);
    // Calling the api
    ProjectService(page, values, projectContextInfo)
      .then((res: any) => {
        // Reset the form after success

        // OVER HERE THE NEW ONE WE WILL HAVE TO HANDLE 


        actions.resetForm()
        // Enable the form
        actions.setSubmitting(false)
        actionAfterCall(res, values);
        if (page === 'uploadUnlabelledSet' || page === 'uploadLabelledSet') {
          console.log('values in process response ====>', values, res)
          const newApiEndpoint = `${getStepsProgressbarApiEndpoint(page)}/${res.data.process_id}`
          setApiEndpoint(newApiEndpoint)
          setDialog(true)
        } else {
          handleNext();
        }
        // show a notification progress has been save
      }).catch((e: any) => {
        actions.setSubmitting(false);
      })
  };

  const formik = useFormik({
    initialValues: getProjectFormInitialValues(page),
    validationSchema: projectFormValidationSchema[page],
    onSubmit: handleSubmit,
  })

  const getFormContent = (page: string) => {
    switch (page) {
      case 'createProject':
        return CreateProjectForm({ formik })
      case 'uploadUnlabelledSet':
        return UploadUnlabelledSetForm({ formik, handleNext: handleNext, handleAction: handleAction })
      case 'uploadLabelledSet':
        return UploadLabelledSetForm({ formik, handleNext, handleAction })
      default:
        return CreateProjectForm({ formik });
    }
  }

  const closeProgressBar = () => {
    setDialog(false)
    setApiEndpoint('')
    handleNext();
  }


  return (
    <>
      {getFormContent(page)}
      {/* {<StepsEventsProgressbar apiEndpoint=''/>} */}

      {dialog && apiEndpoint !== '' && <Dialog open={dialog} fullWidth={true} onClose={() => closeProgressBar()}>
        <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
          <h4>Check Live Status</h4>
          <StepsEventsProgressbar apiEndpoint={apiEndpoint} closeProgressBar={closeProgressBar} />
        </Box>
        <DialogActions>
          <Button variant='contained' onClick={() => closeProgressBar()} sx={{ mt: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>}
    </>
  )
}

export default ProjectForm