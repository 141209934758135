import StepsEventsProgressbar from '@components/shared-ui-components/StepsEventsProgressbar'
import { Dialog, Box, DialogActions, Button } from '@mui/material'
import { getStepsProgressbarApiEndpoint } from '../helper'
import React, { useContext } from 'react'
import ProjectContext from '@context/Project/projectContext'

interface IProgressbarModal {
  page?: string
  open: boolean,
  close: () => void
}

function ProgressbarModal({ page, open, close }: IProgressbarModal) {

  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, } = projectContextInfo;

  const apiEndpoint = `${getStepsProgressbarApiEndpoint(page)}/${projectState.processId}`

  return (
    <Dialog open={open} fullWidth={true}>
      <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
        <h4>Check Live Status</h4>
        <StepsEventsProgressbar apiEndpoint={apiEndpoint} closeProgressBar={close} />
      </Box>
      <DialogActions>
        <Button variant='contained' onClick={close} sx={{ mt: 2 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ProgressbarModal)