import AppRoutes from './routes/AppRoutes';
import './App.css';
import LoaderProvider from '@context/Loader/LoaderProvider';
import AuthProvider from '@context/Auth/authProvider';
import ProjectProvider from '@context/Project/projectProvider';
import Snackbar from '@components/shared-layouts/Snackbar';
import { ThemeProvider } from '@mui/material';
import theme from 'styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoaderProvider>
        <Snackbar>
          <AuthProvider>
            <ProjectProvider>
             <AppRoutes />
            </ProjectProvider>
          </AuthProvider>
        </Snackbar>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;
