/* eslint-disable react-hooks/exhaustive-deps */
import "../styles.css";
import { useEffect, useState, useContext } from "react";
import { IRect, IExportAnnotations } from "@services/dashboardService/types";
import {
  Paper,
  Grid,
  Pagination,
  Box,
  Dialog,
  TextField,
  Chip,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import placeholderImg from "@images/placeholder.png";
import { CustomActionButton } from "@components/shared-ui-components/Buttons";
import ProjectContext from "@context/Project/projectContext";
import { CustomImagePlaceholder } from "../helpers";
import {
  getImageInfoByName,
  saveAnnotations,
  exportAnnotations,
} from "@services/dashboardService";
import { getLocalUrlFromBase64 } from "@utils/imageHelper";
import { ISaveAnnotation } from "@services/dashboardService/types";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { getLabelsV2 } from "@services/projectService";
import "./ImageClassificationDataLabellingPage.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  showErrorSnackbar,
  showSuccessSnacbar,
} from "@components/shared-layouts/Snackbar/helper";
import MetaDataLogsModal from "../MetaDataLogsModal";

interface IDataLabellingState {
  annotations: any[];
  annotation: any;
  activeAnnotations: any[];
  editedAnnotations?: any; // This is used to track the edited annotation
}

const initialState: IDataLabellingState = {
  annotations: [],
  annotation: {},
  activeAnnotations: [],
};

function ImageClassificationDataLabellingPage() {
  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState } = projectContextInfo;
  const labels = projectState.labels ?? [];
  const imageIds = projectState.imageIds ?? [];

  const [dataLabellingState, setDataLabellingState] =
    useState<IDataLabellingState>(initialState);
  const [pageNo, setPageNo] = useState(0);
  const [goToPageNo, setGoToPageNo] = useState<any>();
  const [base64Image, setBase64Image] = useState("");
  // const [oImageSize, setOImageSize] = useState<IRect>({ height: 0, width: 0 }); // original image size
  const [cImageSize, setCImageSize] = useState<IRect>({ height: 0, width: 0 }); // current image size
  const [loading, setLoading] = useState(true);
  const [evalRatio, setEvalRatio] = useState<any>();
  const [evalRatioDialog, setEvalRatioDialog] = useState<boolean>(false);
  const [formattedLabelsDataFromV2Api, setFormattedLabelsDataFromV2Api] =
    useState<any>();
  const [officialImageSize, setOfficialImageSize] = useState<any>();
  const [showLabelsAndShortcuts, setShowLabelsAndShortcuts] =
    useState<boolean>(false);
  const [viewMetadata, setViewMetadata] =
    useState<boolean>(false);

  const [currentLabel, setCurrentLabel] = useState<any>("");

  const [searchParams] = useSearchParams();
  
  const {project_type} = useParams()

  const isFullScreen = searchParams.get("fullScreen");

  const minimumImageHeight = "400";
  const minimumImageWidth = "600";

  console.log("Labels ===>", labels);

  // const element: any = document.querySelector('img[alt="annotation image"]');

  const currentImgWidth =
    window.innerWidth - 100 < Number(minimumImageWidth)
      ? minimumImageWidth
      : window.innerWidth - 100;
  const currentImgHeight =
    window.innerHeight - 140 < Number(minimumImageHeight)
      ? minimumImageHeight
      : window.innerHeight - 140;

  const navigate = useNavigate();

  // const setOriginalImageSizeFromUrl = (url: string) => {
  //   const imgElement = document.createElement("img");
  //   imgElement.src = url;

  //   imgElement.onload = () => {
  //     // Read the image metadata
  //     // const { width, height } = imgElement;
  //     // console.log("original image size", height, width);
  //     // setOImageSize({ width, height });
  //   };
  // };

  const setCurrentImageSize = () => {
    const element: any = document.querySelector('img[alt="annotation image"]');
    if (element) {
      const width = element.width;
      const height = element.height;
      setCImageSize({ width, height });
    }
  };

  const checkAndUpdateLabel = (labelFromApi: any) => {
    // Over here first we are checking if the data is present in the edited state
    const pageNoAnnotationsInState =
      dataLabellingState?.editedAnnotations?.find(
        (x: any) => x.pageNo === pageNo
      );

    if (pageNoAnnotationsInState) {
      // The data if present in the edited state means the user has changed it
      setCurrentLabel(pageNoAnnotationsInState.class_id);
    } else {
      console.log("Label from api");
      // We can directly show the data coming from the api
      setCurrentLabel(labelFromApi);
    }
  };

  useEffect(() => {
    getLabelsV2(projectState.projectId)
      .then((resp) => {
        const formattedApiData: any = [];

        const rawApiData = resp.labels;
        console.log("resp ===>", resp);
        for (let i = 0; i < rawApiData.classes?.length; i++) {
          const newObj = {
            classes: rawApiData.classes[i],
            keys: rawApiData?.keys?.[i] ?? null,
            colors: rawApiData?.colors?.[i] ?? null,
          };
          formattedApiData.push(newObj);
        }

        setFormattedLabelsDataFromV2Api(formattedApiData);
      })
      .catch((e) => console.log(e));
  }, []);

  // This is when we are changing the page no
  useEffect(() => {
    async function fetchData() {
      clearAll();
      try {
        setCurrentImageSize();
        setLoading(true);
        const res: any = await getImageInfoByName(
          projectState.projectId,
          imageIds[pageNo],
          currentImgHeight.toString(),
          currentImgWidth.toString()
        );

        console.log("res =", res);

        const _base64Image = res?.data?.img_base64;
        const localUrl: string = await getLocalUrlFromBase64(
          "data:image/png;base64," + _base64Image
        );
        console.log("localurl", localUrl);

        setBase64Image(localUrl);
        setOfficialImageSize({
          width: res?.data?.size[0],
          height: res?.data?.size[1],
        });
        // setOriginalImageSizeFromUrl(localUrl);
        checkAndUpdateLabel(res?.data?.class_id);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    if (imageIds && imageIds.length > pageNo) {
      fetchData();
    }
  }, [pageNo]);

  const handleImageChange = (page: number) => {
    setPageNo(page - 1);
    setGoToPageNo(page);
    return;
  };

  const handleSave = async () => {
    // We have to make the changes in the data passed
    dataLabellingState.editedAnnotations.forEach((x: any) => {
      delete x.pageNo;
    });

    console.log("Temp edit annotation", dataLabellingState.editedAnnotations);

    const _data: ISaveAnnotation = {
      project_id: projectState.projectId,
      annotations: dataLabellingState.editedAnnotations,
      project_type
    };

    console.log("final data for save ==>", _data);

    try {
      await saveAnnotations(_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    setEvalRatioDialog(false);
    // Over here we need to ask for the eval_ratio

    // Open a modal

    // if the evalation ratio is not set then open the dialog
    if (!evalRatio) {
      setEvalRatioDialog(true);
    } else {
      // if the evaluation dialog is set then directly proceed to submitting the form
      const _data: IExportAnnotations = {
        project_id: projectState.projectId,
        src_dataset: projectState?.src_dataset ?? "unlabelled_set",
        export_only_labelled: true,
        eval_ratio: evalRatio,
      };
      try {
        await exportAnnotations(_data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearAll = () => {
    // Do not change the edited Annotations state for persistent state management
    setDataLabellingState({
      ...initialState,
      editedAnnotations: dataLabellingState.editedAnnotations,
    });

    setCurrentLabel("");
  };

  const getUpdatedEditedAnnotationsInState = (newLabel: any) => {
    // Example i am getting page no 0 and its in teh edited state
    // First we are checking if the edit annotations  in state has this pageNo data
    let pageNoAnnotationsInState = dataLabellingState?.editedAnnotations?.find(
      (x: any) => x.pageNo === pageNo
    );

    if (pageNoAnnotationsInState) {
      // Means we directly have it in edited state we just need to update
      const updatedEditedAnnotations =
        dataLabellingState?.editedAnnotations.map((x: any) => {
          if (x.pageNo === pageNo) {
            return {
              pageNo: x.pageNo,
              image_id: x.image_id,
              class_id: newLabel,
              size: x.actual_image_size,
            };
          } else {
            return x;
          }
        });
      return [...updatedEditedAnnotations];
    } else {
      // Means we  have to directly put it
      const _newEditedAnnotations = {
        pageNo,
        image_id: imageIds[pageNo],
        class_id: newLabel,
        size: [officialImageSize.width, officialImageSize.height],
      };
      if (!dataLabellingState.editedAnnotations) {
        return [_newEditedAnnotations];
      } else {
        return [...dataLabellingState.editedAnnotations, _newEditedAnnotations];
      }
    }
  };

  const handleLabelChange = (value: any) => {
    setDataLabellingState({
      ...dataLabellingState,
      editedAnnotations: getUpdatedEditedAnnotationsInState(value),
    });

    setCurrentLabel(value);
  };

  const shortcutKeypressCallback = (key: string) => {
    console.log("Key was pressed", key);

    // Handle the Delete Key press or Backspace

    if (key === "Backspace" || key === "Delete") {
      // WE WILL NEED TO WRITE THE HANDLE DELETE LOGIC
      // handleDelete(annotation.data.id);
    } else if (key === "ArrowRight" || key === "ArrowLeft") {
      if (key === "ArrowRight") {
        console.log("PageNo", pageNo);
        setPageNo(pageNo + 1);
      }

      if (key === "ArrowLeft") {
        setPageNo(pageNo - 1);
      }
    } else {
      // Now we will check if the shortcut key is valid
      const indexOfShortcut = formattedLabelsDataFromV2Api.findIndex(
        (obj: any) => obj.keys === key
      );
      // console.log('Index of shortcut', indexOfShortcut)

      if (indexOfShortcut !== -1) {
        //Means there is a short cut key
        console.log(
          "shortcut has been found ",
          formattedLabelsDataFromV2Api[indexOfShortcut]
        );
        // Now we can change the value of the label

        // HERE THERRE WILL BE THE LOGIC OF IT below
        handleLabelChange(
          formattedLabelsDataFromV2Api[indexOfShortcut].classes
        );
        showSuccessSnacbar(
          `Annotation has been marked as ${formattedLabelsDataFromV2Api[indexOfShortcut].classes}`
        );
      } else {
        showErrorSnackbar(`No shortcut key found with letter ( ${key} )`);
      }
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key) {
      if (!loading) {
        shortcutKeypressCallback(e.key);
      }
    }
  };

  useEffect(() => {
    console.log("adding the event listener");
    document.addEventListener("keypress", handleKeyPress);
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      console.log("removed the event");
      document.removeEventListener("keypress", handleKeyPress);
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  console.log("formatted data from v2", formattedLabelsDataFromV2Api);

  console.log("current label", currentLabel);
  
  const closeMetadataModal  = () => {
    setViewMetadata(false)
  }


  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: "10px",
        paddingBottom: "0px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div id="annotation-image-header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
            File Name - {imageIds && imageIds[pageNo]}
          </h4>
          <div>
            <Button
              onClick={() => setShowLabelsAndShortcuts(!showLabelsAndShortcuts)}
              variant={showLabelsAndShortcuts ? "contained" : "outlined"}
              color={showLabelsAndShortcuts ? "secondary" : "primary"}
              style={{ marginRight: "12px" }}
            >
              Show Labels & Shortcuts
            </Button>
            <Button
              onClick={() => setViewMetadata(!viewMetadata)}
              variant={viewMetadata ? "contained" : "outlined"}
              color={viewMetadata ? "secondary" : "primary"}
              style={{ marginRight: "12px" }}
            >
              View Metadata
            </Button>
            <Button
              startIcon={
                isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />
              }
              onClick={() => {
                const projectType = localStorage
                  .getItem("projectType")
                  ?.toLowerCase()
                  .replace(/ /g, "_");
                isFullScreen
                  ? navigate(
                      `/${projectType}/operations/${projectState.projectId}/data-labelling/selection`
                    )
                  : navigate(
                      `/${projectType}/operations/${projectState.projectId}/data-labelling/selection?fullScreen=true`
                    );
              }}
              variant="contained"
              color="secondary"
            >
              {isFullScreen ? "Minimize" : "Full Screen"}
            </Button>
          </div>
        </div>

        {showLabelsAndShortcuts && formattedLabelsDataFromV2Api && (
          <div
            style={{ display: "flex", marginBottom: "12px", flexWrap: "wrap" }}
          >
            {formattedLabelsDataFromV2Api.map((x: any) => (
              <Chip
                key={x.classes}
                label={`${x.classes} - ( ${x.keys} )`}
                style={{
                  backgroundColor: x.colors,
                  fontWeight: "900",
                  fontSize: "14px",
                  marginRight: "8px",
                  marginTop: "12px",
                }}
                className="label-tag"
              />
            ))}
          </div>
        )}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ReactPlaceholder
            ready={!loading}
            customPlaceholder={<CustomImagePlaceholder size={cImageSize} />}
          >
            <img
              src={base64Image !== "" ? base64Image : placeholderImg}
              style={{
                width: "100%",
                maxWidth: "100%",
                height: currentImgHeight,
                maxHeight: currentImgHeight,
                // objectFit: 'contain',
                // margin: 'auto'
              }}
              alt="Annotation Imag"
            />
          </ReactPlaceholder>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <Grid
              item
              // xs={12}
              // sm={5}
              // md={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CustomActionButton
                btnName="Save"
                isSubmitting={false}
                btnAction={handleSave}
              />
              <CustomActionButton
                btnName="Export"
                isSubmitting={false}
                btnAction={handleExport}
                style={{ marginLeft: "10px", width: "100%" }}
              />
            </Grid>

            <div style={{ display: "flex", alignItems: "baseline" }}>
              <FormControl sx={{ m: 1, minWidth: 90, maxHeight: 30 }}>
                <InputLabel id="demo-simple-select-label">Label</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentLabel}
                  onChange={(e) => handleLabelChange(e.target.value)}
                  // style={{ height: 40, width: 90 }}
                  style={{ maxHeight: 55 }}
                >
                  {labels?.map((y: any, index: any) => (
                    <MenuItem value={index} key={index}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Pagination
                // sx={{ margin: 'auto' }}
                count={imageIds.length ?? 0}
                showFirstButton
                showLastButton
                onChange={(e, page) => handleImageChange(page)}
                page={pageNo + 1}
              />
              <TextField
                type="number"
                label="Go to Page"
                variant="outlined"
                size="small"
                // value={pageNo + 1}
                value={goToPageNo}
                defaultValue={pageNo + 1}
                style={{ width: "100px" }}
                // onChange={(e) => { Number(e.target.value) <= imageIds.length && handleImageChange(e, Number(e.target.value)) }}
                onChange={(e) => {
                  setGoToPageNo(Number(e.target.value));
                }}
                inputProps={{ min: 0, max: imageIds.length ?? 0 }}
              />
              <Button
                onClick={() => handleImageChange(goToPageNo)}
                variant="outlined"
                style={{ marginLeft: "12px" }}
              >
                Go To page No {goToPageNo}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={evalRatioDialog}
        fullWidth={true}
        onClose={() => setEvalRatioDialog(false)}
      >
        <Box sx={{ p: 2, display: "inline-grid" }}>
          <h4>Please set the Evalution Ratio</h4>
          <TextField
            sx={{ mb: 2 }}
            type="number"
            name="eval_ratio"
            placeholder="Ratio"
            value={evalRatio}
            onChange={(e) => setEvalRatio(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 1, step: 0.05 },
            }}
          />
        </Box>
        <CustomActionButton
          btnName="Proceed"
          isSubmitting={false}
          btnAction={handleExport}
        />
      </Dialog>
      
      {projectState.projectId && imageIds && <MetaDataLogsModal projectId={projectState.projectId as string} imageId={imageIds[pageNo]} showMetadataModal={viewMetadata} closeMetadataModal={closeMetadataModal} />}
      
    </Paper>
  );
}

export default ImageClassificationDataLabellingPage;
