import React, { useContext, useState } from 'react'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { Box, Button, TextField, Dialog, DialogActions } from '@mui/material'
import StepsEventsProgressbar from '@components/shared-ui-components/StepsEventsProgressbar';
import { getActionAfterSubmit, projectFormValidationSchema } from '@components/shared-ui-components/ProjectForm/helper';
import ProjectContext from '@context/Project/projectContext';
import ProjectService from '@services/projectService';
import { getStepsProgressbarApiEndpoint } from '@components/shared-ui-components/DynamicForm/helper';

function OptionsUploadLablledData() {

  const [files, setFiles] = useState<File[]>([]);

  const [dialog, setDialog] = useState(false)
  const [apiEndpoint, setApiEndpoint] = useState('')

  const page = 'uploadLabelledSet'

  const projectContextInfo = useContext(ProjectContext)

  const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
    const actionAfterCall = getActionAfterSubmit(page, projectContextInfo);
    // Calling the api
    ProjectService(page, values, projectContextInfo)
      .then((res: any) => {
        // Reset the form after success
        actions.resetForm()
        // OVER HERE THE NEW ONE WE WILL HAVE TO HANDLE 

        // Enable the form
        actions.setSubmitting(false)
        actionAfterCall(res, values);

        console.log('values in process response ====>', values, res)
        const newApiEndpoint = `${getStepsProgressbarApiEndpoint(page)}/${res.data.process_id}`
        setApiEndpoint(newApiEndpoint)
        setDialog(true)
        // show a notification progress has been save
      }).catch((e: any) => {
        actions.setSubmitting(false);
      })
  }

  const formik: FormikProps<any> = useFormik({
    initialValues: { eval_ratio: null, compressed_file: null },
    validationSchema: projectFormValidationSchema['uploadLabelledSet'],
    onSubmit: handleFormSubmit,
  })

  const { errors, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}
  const { eval_ratio } = values

  const handleSetFiles = (fs: File[]) => {
    setFiles(fs);
    formik.setFieldValue('compressed_file', fs[0])
  }

  const handleUrl = (url: string) => {
    files.length > 0 && setFiles([]);
    formik.setFieldValue('compressed_file', url)
  }

  const handleEvalRatioChange = (value: any) => {
    formik.setFieldValue('eval_ratio', value)
  };

  const closeProgressBar = () => {
    setDialog(false)
    setApiEndpoint('')
  }

  return (
    <>
      <h4>Upload Labelled Data</h4>
      <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
        <Box>
          <div style={{ border: '1px solid black', padding: '12px' }}>
            <CustomFileUpload files={files} setFiles={handleSetFiles} />
            <input id="file" name="compressed_file" type="file" hidden />
            <h4>Or</h4>
            <CustomTextField
              placeholder="Input File link"
              sx={{ mb: 2 }}
              fullWidth
              name="compressed_file"
              value={files.length === 0 ? formik.values.compressed_file : ''}
              onChange={(e) => handleUrl(e.target.value)}
            />
          </div>
          <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />

          <TextField
            sx={{ mt: 2 }}
            type='number'
            name="eval_ratio"
            placeholder="Ratio"
            value={eval_ratio}
            onChange={(e) => handleEvalRatioChange(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 1, step: 0.05 }
            }}

          />
          <ErrorHelperWrapper isError={!!(touched.eval_ratio && errors.eval_ratio)} errorMsg={errors.eval_ratio} helperId='eval_ratio-create-project' />
          <Box sx={{ mb: 2 }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{ mt: 1, mr: 1 }}
              >
                {`Upload`}
              </Button>

            </div>
          </Box>
        </Box>
      </form>

      {dialog && apiEndpoint !== '' && <Dialog open={dialog} fullWidth={true} onClose={() => closeProgressBar()}>
        <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
          <h4>Check Live Status</h4>
          <StepsEventsProgressbar apiEndpoint={apiEndpoint} closeProgressBar={closeProgressBar} />
        </Box>
        <DialogActions>
          <Button variant='contained' onClick={() => closeProgressBar()} sx={{ mt: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>}
    </>
  )
}

export default OptionsUploadLablledData