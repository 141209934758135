import CustomDropdownField from "../CustomDropdownField";
import { SelectProps } from "@mui/material";

interface CustomSelectProjectTypeFieldProps extends SelectProps {
  formik: any;
}

function CustomSelectProjectTypeField(
  props: CustomSelectProjectTypeFieldProps
) {
  const projectTypeList = [
    { value: "Object Detection", label: "Object Detection" },
    { value: "Image Classification", label: "Image Classification" },
    { value: "Object Detection - (CLARIFIER)", label: "Object Detection Clarifier" },
    { value: "Image Classification - (CLARIFIER)", label: "Image Classification Clarifier" },
    { value: "Instance Segmentation - (CLARIFIER)", label: "Instance Segmentation Clarifier" },
    // { value: 'semantic_segmentation', label: 'Semantic Segmentation' },
    // { value: 'instance_segmentation', label: 'Instance Segmentation' },
    // { value: 'panoptic_segmentation', label: 'Panoptic Segmentation' }
  ];

  return <CustomDropdownField dropdownData={projectTypeList} {...props} />;
}

export default CustomSelectProjectTypeField;
