import Ellipsis from "../Ellipsis";
import { CustomTypographyH2, CustomTypographyH3, CustomTypographyH4, CustomTypographyH5, CustomTypographyH6 } from "./style";

interface TypographyProps {
  className?: string;
  ellipsis?: boolean;
  ellipsisWidth?: number;
  children: string;
}

export const TypographyH2 = (props: TypographyProps) => {
  const { children, className, ellipsis, ellipsisWidth } = props;
  return (
    <CustomTypographyH2 className={className}>
      {ellipsis ? (
        <Ellipsis width={ellipsisWidth}>{children}</Ellipsis>
      ) : (
        children
      )}
    </CustomTypographyH2>
  );
};

TypographyH2.defaultProps = {
  className: "",
  ellipsis: false,
  ellipsisWidth: 200,
};

export const TypographyH3 = (props: TypographyProps) => {
  const { children, className, ellipsis, ellipsisWidth } = props;
  return (
    <CustomTypographyH3 className={className}>
      {ellipsis ? (
        <Ellipsis width={ellipsisWidth}>{children}</Ellipsis>
      ) : (
        children
      )}
    </CustomTypographyH3>
  );
};

TypographyH3.defaultProps = {
  className: "",
  ellipsis: false,
  ellipsisWidth: 120,
};

export const TypographyH4 = (props: TypographyProps) => {
  const { children, className, ellipsis, ellipsisWidth } = props;
  return (
    <CustomTypographyH4 className={className}>
      {ellipsis ? (
        <Ellipsis width={ellipsisWidth}>{children}</Ellipsis>
      ) : (
        children
      )}
    </CustomTypographyH4>
  );
};

TypographyH4.defaultProps = {
  className: "",
  ellipsis: false,
  ellipsisWidth: 120,
};

export const TypographyH5 = (props: TypographyProps) => {
  const { children, className, ellipsis, ellipsisWidth } = props;
  return (
    <CustomTypographyH5 className={className}>
      {ellipsis ? (
        <Ellipsis width={ellipsisWidth}>{children}</Ellipsis>
      ) : (
        children
      )}
    </CustomTypographyH5>
  );
};

TypographyH5.defaultProps = {
  className: "",
  ellipsis: false,
  ellipsisWidth: 100,
};

export const TypographyH6 = (props: TypographyProps) => {
  const { children, className, ellipsis, ellipsisWidth } = props;
  return (
    <CustomTypographyH6 className={className}>
      {ellipsis ? (
        <Ellipsis width={ellipsisWidth}>{children}</Ellipsis>
      ) : (
        children
      )}
    </CustomTypographyH6>
  );
};

TypographyH6.defaultProps = {
  className: "",
  ellipsis: false,
  ellipsisWidth: 100,
};
