import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../../CustomDropdownField'
import React from 'react'
import { useParams } from 'react-router-dom'

interface CustomClarifierStrategyProps extends SelectProps {
  formik: any
}

function CustomClarifierStrategy(props: CustomClarifierStrategyProps) {
  
  const {project_type} = useParams()

  const imageClassificationStrategyList = [
    {label: "Least Confidence Sampling Dropout", value: "LeastConfidenceSamplingDropout"},
    {label: "Margin Sampling Dropout", value: "MarginSamplingDropout"},
    {label: "Grad Match Active", value: "GradMatchActive"},
    {label: "K Means Sampling", value: "KMeansSampling"},
    {label: "Batch BALD Dropout", value: "BatchBALDDropout"},
    {label: "Entropy Sampling Dropout", value: "EntropySamplingDropout"},
    {label: "BADGE", value: "BADGE"},
    {label: "Adversarial BIM", value: "AdversarialBIM"},
    {label: "BALD Dropout", value: "BALDDropout"},
    {label: "Margin Sampling", value: "MarginSampling"},
    {label: "Core Set", value: "CoreSet"},
    {label: "Least Confidence Sampling", value: "LeastConfidenceSampling"},
    {label: "FASS", value: "FASS"},
    {label: "Random Sampling", value: "RandomSampling"},
    {label: "Entropy Sampling", value: "EntropySampling"},
    {label: "SubmodularSampling", value: "SubmodularSampling"},
    {label: "AdversarialDeepFool", value: "AdversarialDeepFool"},
    {label: "GLISTER", value: "GLISTER"},
    {label: "FL1MI", value: "FL1MI"},
    {label: "GCMI", value: "GCMI"},
    {label: "FL2MI", value: "FL2MI"},
    {label: "LogDetMI", value: "LogDetMI"},
    {label: "COM", value: "COM"}
]

const objectDetectionStrategyList = [
  {
    label: "Concave Over Modular",
    value: "Concave Over Modular"
  },
  {
    label: "Disparity Min",
    value: "Disparity Min"
  },
  {
    label: "Disparity Sum",
    value: "Disparity Sum"
  },
  {
    label: "Facility Location",
    value: "Facility Location"
  },
  {
    label: "Facility Location Mutual Information",
    value: "Facility Location Mutual Information"
  },
  {
    label: "Facility Location Variant Mutual Information",
    value: "Facility Location Variant Mutual Information"
  },
  {
    label: "Graph Cut",
    value: "Graph Cut"
  },
  {
    label: "Graph Cut Mutual Information",
    value: "Graph Cut Mutual Information"
  },
  {
    label: "Log Determinant",
    value: "Log Determinant"
  },
  {
    label: "Log Determinant Mutual Information",
    value: "Log Determinant Mutual Information"
  },
  {label: "Entropy", value: "Entropy"},
  {label: "Margin", value: "Margin"},
  {label: "Least Confidence", value: "LeastConfidence"},
]



  return (
    <CustomDropdownField
      dropdownData={project_type === 'image_classification_-_(clarifier)'
        ? imageClassificationStrategyList
        : objectDetectionStrategyList
       }
      {...props}
    />
  )
}

export default React.memo(CustomClarifierStrategy)