import React from 'react';
import { Navigate } from 'react-router-dom';

import Cookies from 'js-cookie';

interface ProtectedRouteProps {
  path?: string;
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {

  const cookie = Cookies.get('token')

  return !!cookie ? (
    <>
      {element}
    </>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default ProtectedRoute;