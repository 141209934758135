import {
  IAnnotation,
  IRIA,
  IRect,
  IGeometry,
  IData,
  // ISelection
} from "@services/dashboardService/types";
import { Box } from "@mui/material";
import placeholderImg from "@images/placeholder.png";
const { v4: uuidv4 } = require("uuid");

export const ria_to_annotation = (
  riaList: IRIA[],
  labels: string[],
  oImageSize: IRect,
  imageId: string,
  officialImageSize: IRect
) => {
  //percent to absolute
  console.log("rialist===>", riaList);

  let class_ids: number[] = [];
  let bboxes: any[] = [];

  let _bbox: number[];
  let _class_id: number;
  for (let ria of riaList) {
    _bbox = [
      (ria.geometry.x * oImageSize.width) / 100,
      (ria.geometry.y * oImageSize.height) / 100,
      (ria.geometry.width * oImageSize.width) / 100,
      (ria.geometry.height * oImageSize.height) / 100,
    ];
    bboxes.push(_bbox);
    _class_id = labels.indexOf(ria.data.text);
    class_ids.push(_class_id);
  }

  let annotation: IAnnotation = {
    image_id: imageId,
    bboxes,
    class_ids,
    // size: [oImageSize.height, oImageSize.width]
    size: [officialImageSize.width, officialImageSize.height],
    target_size: [oImageSize.width, oImageSize.height],
  };
  return annotation;
};

export const annotation_to_ria = (
  bboxes: number[][],
  class_ids: number[],
  oImageSize: IRect,
  labels: string[]
) => {
  //percent to absolute

  const len = bboxes?.length ?? 0;
  let riaList: IRIA[] = [];
  if (!len) return [];
  let geometry: IGeometry;
  let data: IData;
  // let selection: ISelection = {anchorX: 0, anchorY: 0, mode: 'EDITING', showEditor: true}
  let bbox: number[] = [];

  for (let i = 0; i < len; i++) {
    bbox = bboxes[i];
    geometry = { type: "RECTANGLE", x: 0, y: 0, width: 0, height: 0 };
    data = { text: "" };

    geometry.x = (bbox[0] / oImageSize.width) * 100 ?? -1;
    geometry.y = (bbox[1] / oImageSize.height) * 100 ?? -1;
    geometry.width = (bbox[2] / oImageSize.width) * 100 ?? -1;
    geometry.height = (bbox[3] / oImageSize.height) * 100 ?? -1;

    // selection.anchorX = bbox[0] / oImageSize.width * 100 ?? -1;
    // selection.anchorY = bbox[1] / oImageSize.height * 100 ?? -1;

    data.text = labels[class_ids[i]] ?? "";
    data.id = uuidv4();
    riaList.push({
      geometry,
      data,
    });
  }

  console.log("======> riaList", riaList);
  return riaList;
};

interface CustomImagePlaceholderProps {
  size: IRect;
}

export function CustomImagePlaceholder({ size }: CustomImagePlaceholderProps) {
  console.log("seize", size);
  let width = !!size.width ? size.width : "100%";
  let height = !!size.height ? size.height : "auto";

  console.log("width, height", width, height);
  return (
    <Box
      component="img"
      sx={{
        width: width,
        height: height,
      }}
      alt="placeholder"
      src={placeholderImg}
    />
  );
}

export function RenderTemplate(props: any) {
  const { children, geometry } = props;

  console.log();
  if (!geometry) return <>{children}</>;

  return (
    <div
      style={{
        color: "black",
        padding: 10,
        fontSize: 12,
        background: "white",
        borderRadius: 3,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height}%`,
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        zIndex: 2,
      }}
    >
      {children}
    </div>
  );
}
