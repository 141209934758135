import { useMemo, useState } from "react";

import ProjectContext, { IProjectState } from './projectContext'

export const initialProjectState: IProjectState = {
    projectId: -1,
    projectName: '',
    projectType: '',
    labels: [],
    imageIds: [],
    prev_annotations: [],
    updated_annotations: [],
    src_dataset: 'unlabelled_set',
}

const ProjectState = (props: { children: React.ReactNode }) => {
    const { children } = props;

    const [state, setState] = useState<IProjectState>(initialProjectState)

    const stateValue = useMemo(() => ({ state, setState }), [state, setState])
    return (
        <ProjectContext.Provider value={stateValue}>
            {children}
        </ProjectContext.Provider>
    )
}

export default ProjectState;