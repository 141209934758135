import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../CustomDropdownField'

interface CustomGeometricTransformsFieldProps extends SelectProps {
  formik: any
}

function CustomGeometricTransformsField(props: CustomGeometricTransformsFieldProps) {

  const geometricTransformsList = [
    { value: 'Dual Transform', label: 'Dual Transform' },
    { value: 'Longest Max Size', label: 'Longest Max Size' },
    { value: 'Random Scale', label: 'Random Scale' },
    { value: 'Smallest Max Size', label: 'Smallest Max Size' },
    { value: 'Random Rotate90', label: 'Random Rotate90' },
    { value: 'Rotate', label: 'Rotate' },
    { value: 'Safe Rotate', label: 'Safe Rotate' },
    { value: 'Affine', label: 'Affine' },
    { value: 'Elastic Transform', label: 'Elastic Transform' },
    { value: 'Flip', label: 'Flip' },
    { value: 'Grid Distortion', label: 'Grid Distortion' },
    { value: 'Horizontal Flip', label: 'Horizontal Flip' },
    { value: 'Optical Distortion', label: 'Optical Distortion' },
    { value: 'Pad If Needed', label: 'Pad If Needed' },
    { value: 'Perspective', label: 'Perspective' },
    { value: 'Piecewise Affine', label: 'Piecewise Affine' },
    { value: 'Shift Scale Rotate', label: 'Shift Scale Rotate' },
    { value: 'Transpose', label: 'Transpose' },
    { value: 'Vertical Flip', label: 'Vertical Flip' }
  ];


  return (
    <CustomDropdownField
      dropdownData={geometricTransformsList}
      {...props}
    />
  )
}

export default CustomGeometricTransformsField