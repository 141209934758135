import { SelectProps } from "@mui/material";
import CustomDropdownField from "../CustomDropdownField";
import React from "react";

interface CustomDataSetFieldProps extends SelectProps {
  formik: any;
}

function CustomDataSetField(props: CustomDataSetFieldProps) {
  const datasetList = [
    {
      label: "None",
      value: "",
    },
    {
      label: "Unlabelled Set",
      value: "unlabelled_set",
    },
    {
      label: "Labelled Set",
      value: "labelled_set",
    },
    {
      label: "Diverse Selection Set",
      value: "diverse_selected_set",
    },
    {
      label: "Augmentation Set",
      value: "augmented_set",
    },
    {
      label: "Auto-Labelled Set",
      value: "auto_labelled_set",
    },
    {
      label: "Active Selection Set",
      value: "active_selected_set",
    },
    {
      label: "Target Selection Set",
      value: "target_selected_set",
    },
    {
      label: "Evaluation Set",
      value: "evaluation_set",
    },
  ];

  console.log("props from the dataset field", props);

  return (
    <CustomDropdownField
      dropdownData={datasetList}
      {...props}
    />
  );
}

export default React.memo(CustomDataSetField);
