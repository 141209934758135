import { SelectProps } from "@mui/material";
import CustomDropdownField from "../CustomDropdownField";
import { modelsList } from "@components/shared-ui-components/DynamicForm/static-data";

interface CustomModelListFieldProps extends SelectProps {
  formik: any;
  modelListName: string;
}

// Currently this Field is being used in diverse selection & Training Page
function CustomModelListField(props: CustomModelListFieldProps) {
  const projectTypePath = localStorage
    .getItem("projectType")
    ?.toLowerCase()
    .replace(/ /g, "_");
    
  const { modelListName } = props;

  let tempModelListName = '' 

  if(modelListName === 'training') {
    if(projectTypePath === 'image_classification' || projectTypePath === 'image_classification_-_(clarifier)') {
      tempModelListName = 'imageClassificationTraining'
    }
    if(projectTypePath === 'object_detection') {
      tempModelListName = 'objectDetectionTraining'
    }
    if(projectTypePath === 'object_detection_-_(clarifier)' ||  projectTypePath === 'instance_segmentation_-_(clarifier)') {
      tempModelListName = 'clarifierModelObjDetTraining'
    }
  } else {
    tempModelListName = modelListName
  }
  
  console.log('modellistname===>', modelListName, tempModelListName)
  
  let modelList = modelsList[tempModelListName];

  return <CustomDropdownField dropdownData={modelList} {...props} />;
}

export default CustomModelListField;
