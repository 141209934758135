import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import DefaultLayout from "@shared-layouts/DefaultLayout";
import MainLayout from "@shared-layouts/MainLayout";
import AuthLayout from "@components/shared-layouts/AuthLayout";
import LoginPage from "@pages/Auth/LoginPage";
import RegisterPage from "@pages/Auth/RegisterPage";
import ForgetPasswordPage from "@pages/Auth/ForgetPasswordPage";
import ProjectsPage from "@pages/ProjectsPage";
import NotFoundPage from "@pages/NotFoundPage";

import DataLabellingPage from "@pages/Operations/DataLabellingPage";
import DataLabellingSelectionPage from "@pages/Operations/DataLabellingPage/DataLabellingSelectionPage";
import DiverseSelectionPage from "@pages/Operations/DiverseSelectionPage";
import AugmentationPage from "@pages/Operations/AugmentationPage";
import AutoLabellingPage from "@pages/Operations/AutoLabellingPage";
import TrainingPage from "@pages/Operations/TrainingPage";
import EvaluationPage from "@pages/Operations//EvaluationPage";
import ActiveSelectionPage from "@pages/Operations/ActiveLearningPage";
import TargetSelectionPage from "@pages/Operations/TargetLearningPage";
import EvaluationLogsPage from "@pages/Operations/EvaluationPage/EvaluationLogsPage";
import ProtectedRoute from "./ProtectedRoute";
import ActiveSelectionHistoryPage from "@pages/Operations/ActiveLearningPage/ActiveLearningHistoryPage";
import TargetSelectionHistoryPage from "@pages/Operations/TargetLearningPage/TargetLearningHistoryPage";
import LogsPage from "@pages/Tasks/LogsPage";
import UploadDataPage from "@pages/Tasks/UploadDataPage";
import LabelsPage from "@pages/Tasks/LabelsPage";
import InferencePage from "@pages/Operations/InferencePage";
import InferenceLogsPage from "@pages/Operations/InferencePage/InferenceLogsPage";
import ExportPage from "@pages/Operations/ExportPage";
import ClarifierPage from "@pages/Operations/CalrifierPage";
import ClarifierLogsPage from "@pages/Operations/CalrifierPage/ClarifierLogsPage";
import RelevantDataSelectionPage from "@pages/Operations/RelevantDataSelectionPage";

const router = createBrowserRouter(
  [
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <RegisterPage /> },
        { path: "forget-password", element: <ForgetPasswordPage /> },
      ],
    },
    {
      path: "/projects",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <ProtectedRoute path="" element={<ProjectsPage />} />,
        },
      ],
    },
    {
      path: "/:project_type/operations/:projectId",
      element: <DefaultLayout />,
      children: [
        {
          path: "data-labelling",
          element: <ProtectedRoute path="" element={<DataLabellingPage />} />,
        },
        {
          path: "data-labelling/selection",
          // Here is the main labelling data
          element: (
            <ProtectedRoute path="" element={<DataLabellingSelectionPage />} />
          ),
        },
        {
          path: "diverse-selection",
          element: (
            <ProtectedRoute path="" element={<DiverseSelectionPage />} />
          ),
        },
        {
          path: "initial-selection",
          element: (
            <ProtectedRoute path="" element={<DiverseSelectionPage />} />
          ),
        },
        {
          path: "augmentation",
          element: <ProtectedRoute path="" element={<AugmentationPage />} />,
        },
        {
          path: "auto-labelling",
          element: <ProtectedRoute path="" element={<AutoLabellingPage />} />,
        },
        {
          path: "training",
          element: <ProtectedRoute path="" element={<TrainingPage />} />,
        },
        {
          path: "evaluation",
          element: <ProtectedRoute path="" element={<EvaluationPage />} />,
        },
        {
          path: "evaluation/logs",
          element: <ProtectedRoute path="" element={<EvaluationLogsPage />} />,
        },
        {
          path: "evaluation/logs/:logId",
          element: <ProtectedRoute path="" element={<EvaluationLogsPage />} />,
        },
        {
          path: "clarifier",
          element: <ProtectedRoute path="" element={<ClarifierPage />} />,
        },
        {
          path: "clarifier/history",
          element: <ProtectedRoute path="" element={<ClarifierLogsPage />} />,
        },
        {
          path: "active-selection",
          element: <ProtectedRoute path="" element={<ActiveSelectionPage />} />,
        },
        {
          path: "active-selection/history",
          element: (
            <ProtectedRoute path="" element={<ActiveSelectionHistoryPage />} />
          ),
        },
        {
          path: "target-selection",
          element: <ProtectedRoute path="" element={<TargetSelectionPage />} />,
        },
        {
          path: "target-selection/history",
          element: (
            <ProtectedRoute path="" element={<TargetSelectionHistoryPage />} />
          ),
        },
        {
          path: "inference",
          element: <ProtectedRoute path="" element={<InferencePage />} />,
        },
        {
          path: "inference/logs",
          element: <ProtectedRoute path="" element={<InferenceLogsPage />} />,
        },
        {
          path: "relevant-data-selection",
          element: <ProtectedRoute path="" element={<RelevantDataSelectionPage />} />,
        },
        {
          path: "export",
          element: <ProtectedRoute path="" element={<ExportPage />} />,
        },
      ],
    },
    {
      path: "/:project_type/options/:projectId",
      element: <DefaultLayout />,
      children: [
        {
          path: "logs",
          element: <ProtectedRoute path="" element={<LogsPage />} />,
        },
        {
          path: "upload-data",
          element: <ProtectedRoute path="" element={<UploadDataPage />} />,
        },
        {
          path: "labels",
          element: <ProtectedRoute path="" element={<LabelsPage />} />,
        },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/auth/login" />,
    },

    { path: "*", element: <NotFoundPage /> },
  ],
  { basename: "/" }
);

function AppRoutes() {
  return <RouterProvider router={router} />;
}

export default AppRoutes;
