import MultipleTable from '@components/shared-ui-components/MultipleTable'
import { Dialog, Box, DialogActions, Button } from '@mui/material'
import React from 'react'

interface IEvaluationLogsModal {
  projectId?: string,
  logsId?: string,
  showLogsModal: boolean,
  closeLogsModal: () => void
}

function EvaluationLogsModal({ projectId, logsId, showLogsModal, closeLogsModal }: IEvaluationLogsModal) {

  const apiEndpoint = `/operations/evaluation/logs/${projectId}/${logsId}` // over here is the api 
  return (
    <Dialog open={showLogsModal} fullWidth={true} maxWidth={false}>
      <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
        <h4 style={{ marginBottom: 0, textTransform: 'capitalize' }}>{logsId}</h4>
        <MultipleTable apiEndpoint={apiEndpoint} />
      </Box>
      <DialogActions>
        <Button variant='contained' onClick={closeLogsModal} sx={{ mt: 2 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EvaluationLogsModal