import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'
import React from 'react'
import { useParams } from 'react-router-dom'

interface CustomLabelledDataSetFieldProps extends SelectProps {
  formik: any
  page?: string
}

function CustomLabelledDataSetField(props: CustomLabelledDataSetFieldProps) {
  
  const {project_type} = useParams()

  let labelledDatasetList = [
    {
      label: 'Labelled Set',
      value: 'labelled_set'
    },
    {
      label: 'Augmentation Set',
      value: 'augmented_set'
    },
    {
      label: 'Auto-Labelled Set',
      value: 'auto_labelled_set'
    },
    {
      label: 'Evaluation Set',
      value: 'evaluation_set'
    },
  ]

  var variables: any = {
    'augmentation': 1
  };

  const { page } = props

  const indexToRemove = page && variables[page]

  let filteredLabelledDatasetList = page
    ? labelledDatasetList.filter(function (_, index) {
      return index !== indexToRemove;
    })
    : labelledDatasetList
    
  if(project_type === 'image_classification_-_(clarifier)' || project_type === 'object_detection_-_(clarifier)' || project_type === 'instance_segmentation_-_(clarifier)') {
    filteredLabelledDatasetList = filteredLabelledDatasetList.filter(x => x.value === 'labelled_set' || x.value === 'augmented_set')
  } // We are showing only 2 fileds for this in training clarifier

  return (
    <CustomDropdownField
      dropdownData={filteredLabelledDatasetList}
      {...props}
    />
  )
}

export default React.memo(CustomLabelledDataSetField)