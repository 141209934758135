import { Outlet, useSearchParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { Box } from "@mui/material";

const DefaultLayout = () => {

    const [searchParams] = useSearchParams();

    const isFullScreen = searchParams.get('fullScreen')

    return (
        <>
            <div style={{ display: 'flex' }}>
                {!isFullScreen && <div>
                    <Sidebar />
                </div>}
                <Box sx={{ display: 'flex', padding: { xs: '10px', sm: '14px 25px' }, marginTop: `${isFullScreen ? '0px' : '80px'}`, width: '100vw' }}>
                    <Outlet />
                </Box>
            </div>
        </>

    )
}


export default DefaultLayout

