import { TextField, TextFieldProps } from '@mui/material'

function CustomNumberField(props: TextFieldProps) {
  return (
    <TextField
      sx={{ mb: 2 }}
      type='number'
      {...props}
    />
  )
}

export default CustomNumberField