import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../../CustomDropdownField'
import React from 'react'

interface CustomAutoLbStrategyProps extends SelectProps {
  formik: any
}

function CustomAutoLbStrategy(props: CustomAutoLbStrategyProps) {

  const autoLbList = [
    {label: "High Confidence", value: "High Confidence"}
]

  return (
    <CustomDropdownField
      dropdownData={autoLbList}
      {...props}
    />
  )
}

export default React.memo(CustomAutoLbStrategy)