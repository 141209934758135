import * as React from 'react';
import { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from '@context/Auth/authContext'
import { useNavigate } from 'react-router-dom';

const settings = ['Profile'];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const authContextInfo = useContext(AuthContext);
  const { state: userState, logout } = authContextInfo;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const username = userState?.name;

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorElUser(null);
    navigate('/')
  }

  return (
    <AppBar position="static" style={{ backgroundColor: 'rgb(17 25 60)', height: '75px', width: '100%' }}>
      <Container style={{ display: 'flex', alignItems: 'center', padding: '0px', height: '100%', margin: 'auto', width: 0, minWidth: '100%' }}>
        <Typography
          component="a"
          href="/"
        >
          <Toolbar style={{ backgroundColor: 'rgb(17 25 60)', height: '100%', width: '200px', textAlign: 'center' }}>
            <img style={{ width: '-webkit-fill-available', height: '55px' }} src="avawatz-logo.svg" alt='Logo' />
          </Toolbar>
        </Typography>
        <Typography variant="h6" noWrap component="div" style={{ fontWeight: 900, color: 'white', margin: 'auto' }}>
          {`Projects`}
        </Typography>

        <Box style={{ padding: '12px' }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, backgroundColor: 'white' }}>
              <Avatar> {username[0]?.toUpperCase() ?? 'A'} </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
            <MenuItem key={`Logout`} onClick={handleLogout}>
              <Typography textAlign="center">{`Logout`}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
