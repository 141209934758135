import { getTargetSelectionHistory } from '@services/dashboardService'
import { useEffect, useState, useMemo } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useParams } from 'react-router-dom';

interface ILog {
  pid: string,
  name: string,
  model_id: string
  input_set: string,
  out_images: number
}

function TargetSelectionHistoryPage() {

  const { projectId } = useParams()

  const [historyLogs, setHistoryLogs] = useState<ILog[] | undefined>([])
  const [loading, setLoading] = useState<boolean>(false)

  const columns = useMemo<MRT_ColumnDef<ILog>[]>(
    () => [
      {
        accessorKey: 'pid',
        header: 'PID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name of iteration',
        // size: 140
      },
      {
        accessorKey: 'model_id',
        header: 'WandB model ID',
        // size: 140,
      },
      {
        accessorKey: 'input_set',
        header: 'Input Set',
        // size: 140,
      },
      {
        accessorKey: 'out_images',
        header: 'Number of output images',
        // Cell: ({ cell }: any) => <Link to={`/operations/${projectId}/evaluation/logs/${cell.getValue()}`}>{cell.getValue()}</Link>
        // size: 140
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formatLogs = (logs: any) => {
    let _logs: ILog[] = [];
    const currentLogs = logs?.logs;
    if (currentLogs?.length > 0) {

      currentLogs?.forEach((it: any, idx: any) => {
        let item = {
          key: `${idx}`,
          pid: it.pid,
          name: it.name,
          model_id: it.model_id,
          input_set: it.unlabelled_set,
          out_images: it.out_images
        }
        _logs.push(item)
      });

    }
    setHistoryLogs(_logs);
    setLoading(false)
  }

  useEffect(() => {
    if (projectId) {
      setLoading(true)
      getTargetSelectionHistory(projectId)
        .then((logs: any) => {
          formatLogs(logs)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {historyLogs && <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            // size: 120,
          },
        }}
        muiTablePaperProps={{ style: { width: '100%' } }}
        columns={columns}
        data={historyLogs}
        enableColumnOrdering
        positionActionsColumn="last"
        state={{ isLoading: loading }}
      />}
    </>
  )
}

export default TargetSelectionHistoryPage