import { getEvaluationLogs } from '@services/dashboardService'
import { useEffect, useState, useMemo } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { Button } from '@mui/material';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useNavigate, useParams } from 'react-router-dom';
import EvaluationLogsModal from './EvaluationLogsModal';
import { getProjectType } from '@utils/index';

interface ILog {
  log_evaluation: string,
  log_date: string;
  log_time: string;
  log_name: string;
}

function EvaluationLogsPage() {

  const { projectId, logId: paramsLogId } = useParams()
  
  const projectType = getProjectType()
  
  console.log('projectType ===>', projectType )
  
  

  const [evaluationLogs, setEvaluationLogs] = useState<ILog[] | undefined>([])

  const [showLogsModal, setShowLogsModal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const closeLogsModal = () => {
    navigate(`/${projectType}/operations/${projectId}/evaluation/logs`)
  }

  const columns = useMemo<MRT_ColumnDef<ILog>[]>(
    () => [
      {
        accessorKey: 'log_evaluation',
        header: 'Mode of Evaluation',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'log_date',
        header: 'Date',
        // size: 140
      },
      {
        accessorKey: 'log_time',
        header: 'Type',
        // size: 140,
      },
      {
        accessorKey: 'log_name',
        header: 'Name',
        Cell: ({ cell }: any) => <Button variant='contained' onClick={() => goToLink(cell.getValue())} sx={{ mt: 2 }} endIcon={<SlideshowIcon />}> {`Show Logs`} </Button>,
        // size: 140
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goToLink = (logId: string) => {
    navigate(`/${projectType}/operations/${projectId}/evaluation/logs/${logId}`)
  }

  const formatEvaluationLogs = (logs: any) => {
    let _logs: ILog[] = [];
    const currentLogs = logs?.logs;

    if (currentLogs?.length > 0) {

      currentLogs?.forEach((it: any, idx: any) => {
        // Splitting the string based on '_' separator
        const parts = it?.split('_');
        // Extracting the desired values
        const evaluation = parts[0] === 'ground' ? 'Ground Truth' : 'Automatic'; // 'auto'

        const date = parts[1]; // '07-03-2023'
        const time = parts[2]?.split('.')[0]; // '04:31:01'
        let item = {
          key: `${idx}`,
          log_evaluation: evaluation,
          log_date: date,
          log_time: time,
          log_name: it,
        }
        _logs.push(item)
      });

    }
    setEvaluationLogs(_logs);
    setLoading(false)
  }

  useEffect(() => {
    if (projectId) {
      setLoading(true)
      getEvaluationLogs(projectId)
        .then((logs: any) => {
          formatEvaluationLogs(logs)

        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (paramsLogId) {
      setShowLogsModal(true)
    } else {
      setShowLogsModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsLogId])

  return (
    <>
      {evaluationLogs && <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            // size: 120,
          },
        }}
        muiTablePaperProps={{ style: { width: '100%', textTransform: 'capitalize' } }}
        columns={columns}
        data={evaluationLogs}
        enableColumnOrdering
        positionActionsColumn="last"
        state={{ isLoading: loading }}
      />}
      {projectId && paramsLogId && <EvaluationLogsModal projectId={projectId} logsId={paramsLogId} showLogsModal={showLogsModal} closeLogsModal={closeLogsModal} />}
    </>
  )
}

export default EvaluationLogsPage