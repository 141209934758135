import React from 'react';

// material-ui
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Typography
} from '@mui/material';

// custom ui
import { ErrorHelper as ErrorHelperWrapper, CustomTextField, CustomPasswordField } from '@components/shared-ui-components/CustomFields';
import { CustomSubmitButton, CustomLinkButton } from '@components/shared-ui-components/Buttons'

// ============================|| LOGIN FORM ||============================ //

const AuthLogin = (formik: any) => {
  const [checked, setChecked] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { errors, handleBlur, handleChange, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}

  return (
    <form noValidate onSubmit={handleSub}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth={true}
            id="username-login"
            autoComplete='off'
            value={values.username}
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.username && errors.username)}
            label="Username"
            placeholder="Enter Username"
          />
          <ErrorHelperWrapper isError={!!(touched.username && errors.username)} errorMsg={errors.username} helperId='username-login' />
        </Grid>
        {/* <Grid item xs={12}>
          <CustomEmailField
            id="email-login"
            autoComplete='email'
            value={values.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.email && errors.email)}
            label="Email Address"
          />
          <ErrorHelperWrapper isError={!!(touched.email && errors.email)} errorMsg={errors.email} helperId='email-login' />
        </Grid> */}
        <Grid item xs={12}>
          <CustomPasswordField
            error={Boolean(touched.password && errors.password)}
            id="-password-login"
            autoComplete='off'
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          />
          <ErrorHelperWrapper isError={!!(touched.password && errors.password)} errorMsg={errors.password} helperId='password-login' />
        </Grid>

        <Grid item xs={12} sx={{ mt: -1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography variant="h6">Remember Me</Typography>}
            />
            <CustomLinkButton linkTo='/auth/forget-password' label='Forgot Password?' />
          </Stack>
        </Grid>
        {errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{errors.submit.toString()}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomSubmitButton btnName="Login" isSubmitting={isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthLogin;
