import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../CustomDropdownField'

interface CustomPixelTransformsFieldProps extends SelectProps {
  formik: any
}

function CustomPixelTransformsField(props: CustomPixelTransformsFieldProps) {

  const pixelTransformsList = [
    { value: 'CLAHE', label: 'CLAHE' },
    { value: 'Channel Shuffle', label: 'Channel Shuffle' },
    { value: 'Color Jitter', label: 'Color Jitter' },
    { value: 'Downscale', label: 'Downscale' },
    { value: 'Dual Transform', label: 'Dual Transform' },
    { value: 'Emboss', label: 'Emboss' },
    { value: 'Equalize', label: 'Equalize' },
    { value: 'Fancy PCA', label: 'Fancy PCA' },
    { value: 'From Float', label: 'From Float' },
    { value: 'Gauss Noise', label: 'Gauss Noise' },
    { value: 'Hue Saturation Value', label: 'Hue Saturation Value' },
    { value: 'ISO Noise', label: 'ISO Noise' },
    { value: 'Image Compression', label: 'Image Compression' },
    { value: 'Invert Img', label: 'Invert Img' },
    { value: 'Jpeg Compression', label: 'Jpeg Compression' },
    { value: 'Multiplicative Noise', label: 'Multiplicative Noise' },
    { value: 'Normalize', label: 'Normalize' },
    { value: 'Pixel Dropout', label: 'Pixel Dropout' },
    { value: 'Posterize', label: 'Posterize' },
    { value: 'RGB Shift', label: 'RGB Shift' },
    { value: 'Random Brightness', label: 'Random Brightness' },
    { value: 'Random Brightness Contrast', label: 'Random Brightness Contrast' },
    { value: 'Random Contrast', label: 'Random Contrast' },
    { value: 'Random Fog', label: 'Random Fog' },
    { value: 'Random Gamma', label: 'Random Gamma' },
    { value: 'Random Gravel', label: 'Random Gravel' },
    { value: 'Random Grid Shuffle', label: 'Random Grid Shuffle' },
    { value: 'Random Rain', label: 'Random Rain' },
    { value: 'Random Shadow', label: 'Random Shadow' },
    { value: 'Random Snow', label: 'Random Snow' },
    { value: 'Random Sun Flare', label: 'Random Sun Flare' },
    { value: 'Random Tone Curve', label: 'Random Tone Curve' },
    { value: 'Ringing Overshoot', label: 'Ringing Overshoot' },
    { value: 'Sharpen', label: 'Sharpen' },
    { value: 'Solarize', label: 'Solarize' },
    { value: 'Spatter', label: 'Spatter' },
    { value: 'Superpixels', label: 'Superpixels' },
    { value: 'Template Transform', label: 'Template Transform' },
    { value: 'To Float', label: 'To Float' },
    { value: 'To Gray', label: 'To Gray' },
    { value: 'To RGB', label: 'To RGB' },
    { value: 'To Sepia', label: 'To Sepia' },
    { value: 'Unsharp Mask', label: 'Unsharp Mask' }
  ];


  return (
    <CustomDropdownField
      dropdownData={pixelTransformsList}
      {...props}
    />
  )
}

export default CustomPixelTransformsField