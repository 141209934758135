import { Dialog, Box, DialogActions, Button } from '@mui/material'

interface IOperationSuccessfullyCompletedModal {
  open: boolean,
  close: () => void
  handleCheckLiveStatus: () => void
}

function OperationSuccessfullyCompletedModal({ open, close, handleCheckLiveStatus }: IOperationSuccessfullyCompletedModal) {
  return (
    <Dialog open={open} onClose={close} fullWidth={true}>
      <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
        <h4>Operation Successful</h4>
        <p style={{ marginTop: '0px' }}>The Operation has been successfully initiated</p>
      </Box>
      <DialogActions>
        <Button onClick={handleCheckLiveStatus} variant='contained'>
          Check Live Status
        </Button>
        <Button variant='outlined' onClick={close}>
          Run In Background
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OperationSuccessfullyCompletedModal