import { deleteInferenceDownloads, getInferenceLogs } from '@services/dashboardService'
import { useEffect, useState, useMemo } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useParams } from 'react-router-dom';
import EyeShowIcon from '@icons/EyeShowIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, Button } from '@mui/material'
import ViewInferenceLogsPage from './ViewInferenceLogsPageDialog';
import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper';


interface ILog {
  pid: string,
  model_name: string,
  wandb_id: string
  data_source: string,
  inference_time: string,
  download_id: string
}

interface IviewInferenceLogsModal {
  download_id: string,
  modal: boolean
}

function InferenceLogsPage() {


  const { projectId } = useParams()

  const [historyLogs, setHistoryLogs] = useState<ILog[] | undefined>([])

  const [loading, setLoading] = useState<boolean>(false)

  const [deleteConfirmationdialogOpenStatus, setDeleteConfirmationdialogOpenStatus] = useState<boolean>(false)

  const [viewInferenceLogsModal, setViewInferenceLogsModal] = useState<IviewInferenceLogsModal>({ download_id: '', modal: false })

  const viewInferenceLog = (download_id: string) => {
    console.log(download_id)
    // Over here we have to show the popup
    setViewInferenceLogsModal({ download_id, modal: true })
  }

  console.log('History logs ', historyLogs)

  const deleteInferenceLog = (fileName: string, pid: string) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      !confirm(`Are you sure you want to delete ${pid}`)
    ) {
      return;
    }
    deleteInferenceDownloads(projectId as string, fileName)
      .then(resp => {
        showSuccessSnacbar('Log has been deleted')
        getInferenceLogs(projectId as string)
          .then((logs: any) => {
            formatLogs(logs)
          })
          .catch((e: any) => {
            console.log(e)
          })
      })
      .catch(err => showErrorSnackbar('Something went wrong'))
  }

  const columns = useMemo<MRT_ColumnDef<ILog>[]>(
    () => [
      {
        accessorKey: 'pid',
        header: 'PID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'model_name',
        header: 'Model Name',
        // size: 140
      },
      {
        accessorKey: 'wandb_id',
        header: 'WandB ID',
        // size: 140,
      },
      {
        accessorKey: 'inference_time',
        header: 'Inference Time',
        // size: 140,
      },
      {
        accessorKey: 'data_source',
        header: 'Data Source',
        // Cell: ({ cell }: any) => <Link to={`/operations/${projectId}/evaluation/logs/${cell.getValue()}`}>{cell.getValue()}</Link>
        // size: 140
      },
      {
        accessorKey: 'download_id',
        header: 'Actions',
        Cell: ({ cell, row }: any) => <>
          <Button variant='outlined' onClick={() => viewInferenceLog(cell.getValue())} sx={{ mt: 2 }}> <EyeShowIcon /> </Button>
          <Button style={{ marginLeft: '8px' }} variant='outlined' onClick={() => deleteInferenceLog(cell.getValue(), cell.getValue('pid'))} sx={{ mt: 2 }}> <DeleteIcon /> </Button>
        </>
        // size: 140
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formatLogs = (logs: any) => {
    let _logs: ILog[] = [];
    const currentLogs = logs?.logs;
    if (currentLogs?.length > 0) {

      currentLogs?.forEach((it: ILog, idx: any) => {
        let item = {
          key: `${idx}`,
          pid: it.pid,
          model_name: it.model_name,
          wandb_id: it.wandb_id,
          data_source: it.data_source,
          inference_time: it.inference_time,
          download_id: it.download_id
        }
        _logs.push(item)
      });

    }
    setHistoryLogs(_logs);
    setLoading(false)
  }

  useEffect(() => {
    if (projectId) {
      setLoading(true)
      getInferenceLogs(projectId)
        .then((logs: any) => {
          formatLogs(logs)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {historyLogs && <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            // size: 120,
          },
        }}
        muiTablePaperProps={{ style: { width: '100%' } }}
        columns={columns}
        data={historyLogs}
        enableColumnOrdering
        positionActionsColumn="last"
        state={{ isLoading: loading }}
      />}

      {viewInferenceLogsModal?.modal && <Dialog style={{ padding: '10px' }} open={viewInferenceLogsModal.modal} onClose={() => setViewInferenceLogsModal({ download_id: '', modal: false })}>

        <ViewInferenceLogsPage download_id={viewInferenceLogsModal.download_id} />

      </Dialog>}

      {/* Dialog for changing the defualt settings */}
      <Dialog open={deleteConfirmationdialogOpenStatus} onClose={() => setDeleteConfirmationdialogOpenStatus(false)} fullWidth={true}>

        <h4>Are you sure you want to delete this Log ?</h4>

        <Button variant='outlined' sx={{ mt: 2 }}> No </Button>
        <Button variant='outlined' sx={{ mt: 2 }}> Yes </Button>

      </Dialog>

    </>
  )
}

export default InferenceLogsPage







