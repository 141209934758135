
import * as Yup from 'yup';
import { IProjectContext } from '@context/Project/projectContext'
import { showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper';

export const projectFormJson: any = {
    createProject: [
        { type: 'projectTypeList', name: 'projectType', defaultValue: '' },
        { type: 'string', name: 'projectName', defaultValue: '' },
    ],

    uploadUnlabelledSet: [
        { type: 'file', name: 'compressed_file', defaultValue: null },
    ],
    uploadLabelledSet: [
        { type: 'file', name: 'compressed_file', defaultValue: null },
        { type: 'number', name: 'eval_ratio', defaultValue: null },
    ]
}

export const getProjectFormInitialValues = (page: string) => {
    let initialValues: any = {};
    projectFormJson[page]?.map((x: any) => {
        if (x.type === 'file') {
            return initialValues[x.name] = x.defaultValue;
        }
        return initialValues[x.name] = x?.defaultValue || '';
    })
    return initialValues;
}

export const projectFormValidationSchema: any = {
    createProject: Yup.object().shape({
        projectType: Yup.string().max(255).required('Project Type is required'),
        projectName: Yup.string().max(255).required('Project Name is required')
    }),

    uploadUnlabelledSet: Yup.object().shape({
        compressed_file: Yup.mixed().required("File is required")
    }),
    uploadLabelledSet: Yup.object().shape({
        compressed_file: Yup.mixed().required("File is required"),
        eval_ratio: Yup.number().required('Evaluation Ratio is required')
    })
}

export const getActionAfterSubmit = (page: string, projectContextInfo: IProjectContext) => {
    const { setState: setProjectState } = projectContextInfo;
    switch (page) {
        case 'createProject':

            return (res: any, values: any) => {
                setProjectState({
                    projectId: res.data.project_id,
                    projectName: values.projectName,
                    projectType: values.projectType,
                })
                showSuccessSnacbar('Project name saved')
            }

        case "uploadUnlabelledSet":
            return () => {
                showSuccessSnacbar('Successfully uploaded unlabelled set')
            }
        case "uploadLabelledSet":
            return () => {
                showSuccessSnacbar('Successfully upload labelled set')
            }
        default:
            return () => { }
    }
}