export const userApiEndPoint = {
  register: "/auth/register_user",
  login: "/auth/login_user",
};

export const projectApiEntryPoint = (pId?: number | string) => {
  return {
    getProjects: "/projects",
    createProject: "/projects",
    createLabels: `/projects/v2/${pId}/labels`,
    updateLabels: `/projects/v2/${pId}/labels/update`,
    deleteLabels: `/projects/${pId}/labels`,
    uploadUnlabelledSet: "/projects/unlabelled_data",
    uploadLabelledSet: "/projects/labelled_data",
  };
};

projectApiEntryPoint.defaultProps = {
  pId: 0,
};

export const dashboardApiEntryPoint = (
  pId?: number | string,
  src_dataset?: string,
  project_type?: string
) => {

  return {
    getUnLabelSetInfoByPId: `/data/${pId}/${src_dataset}`,
    getLabelSetInfoByPId: `/data/${pId}/unlabelled_set`,
    getImageInfoByName: `/data/${pId}/image/`,
    // saveAnnotations: `/${project_type}/data/annotations`,
    exportAnnotations: "/data/annotations/export_to_db",
  };
};

dashboardApiEntryPoint.defaultProps = {
  pId: 0,
  src_dataset: "unlabelled_set",
};
