import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../CustomDropdownField'

interface CustomSelectAugmentationFieldProps extends SelectProps {
  formik: any
}

function CustomSelectAugmentationField(props: CustomSelectAugmentationFieldProps) {

  const augmentationList = [
    { value: 'augmentation', label: 'Augmentation' },
    { value: 'copyPasteAugmentation', label: 'Copy Paste Augmentation' }
  ]

  return (
    <CustomDropdownField
      dropdownData={augmentationList}
      {...props}
    />
  )
}

export default CustomSelectAugmentationField