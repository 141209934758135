import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import './styles.scss';
import services from '@services/index';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Plot from "react-plotly.js";
import { TailSpin } from 'react-loader-spinner';

interface TableData {
  name: string;
  data: {
    columns: string[];
    index: string[];
    data: string[][];
  };
}

interface PlotItem {
  data: any[]
  layout: any[]
}

interface PlotsData {
  name: string,
  plot: PlotItem
}

interface ApiData {
  tables: TableData[],
  plots: PlotsData[]
}

interface MultipleTableProps {
  apiEndpoint?: string;
}

// const data1: TableData[] = [

//   {
//     name: 'relative data',
//     data: {
//       columns: ['col 1', 'col 2'],
//       index: ['row 1', 'row 2'],
//       data: [['a', 'b'], ['c', 'd']],
//     },
//   },
//   {
//     name: 'test table1',
//     data: {
//       columns: ['col 1', 'col 2'],
//       index: ['row 11', 'row 22'],
//       data: [['aa', 'bb'], ['cc', 'dd']],
//     },
//   },
// ];

const initialData = {
  tables: [],
  plots: []
}


const MultipleTable = ({ apiEndpoint }: MultipleTableProps) => {

  const [data, setData] = useState<ApiData>(initialData)

  const [loading, setLoading] = useState<boolean>(false)


  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const callApiEndpoint = () => {
    if (apiEndpoint) {
      setLoading(true)
      services.get(apiEndpoint)
      .then((resp: any) => { setData(resp.data); setLoading(false); console.log('resp.data ==>', resp.data) })
      .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    callApiEndpoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint])
  
  
  console.log('data plots', data.plots?.length !)

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Table's" value="1" />
              {data.plots && data?.plots?.length !== 0 && <Tab label="Plots" value="2" />}
            </TabList>
          </Box>
          {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
              <TailSpin height={35} width={35} color={'#ED8622'} />
            </div>
            : <TabPanel value="1" style={{ padding: 0 }}>
              {data?.tables?.map((table, index) => (
                <div key={index}>
                  <h4 style={{ fontWeight: '600', textTransform: 'capitalize' }}>{table.name}</h4>
                  <Table
                    className="custom-table"
                    dataSource={table?.data?.data?.map((row, rowIndex) =>
                      Object.fromEntries([['index', table?.data?.index[rowIndex]], ...row.map((cell, cellIndex) => [`col${cellIndex}`, cell])])
                    )}
                    key={index}
                    columns={[
                      ...table?.data?.columns?.map((column, columnIndex) => ({
                        title: column,
                        dataIndex: `col${columnIndex}`,
                        key: `col${columnIndex}`,
                      })),
                    ]}
                    pagination={false}
                    bordered
                  />
                </div>
              ))}
            </TabPanel>}
          {data?.plots?.length !== 0 &&
            <TabPanel value="2" style={{ padding: 0 }}>
              {data?.plots?.map(x => (
                <div key={x.name}>
                  <h4 style={{ fontWeight: '600', textTransform: 'capitalize' }}>{x.name}</h4>
                  <Plot
                    data={x.plot.data}
                    layout={x.plot.layout as any}
                    className="analytics-content-plot"
                  />
                </div>
              ))}

            </TabPanel>
          }

        </TabContext>
      </Box>



    </div>
  );
};

export default React.memo(MultipleTable);
