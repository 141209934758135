import { getClarifierLogs } from '@services/dashboardService'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import VisualizeInWandb from '@icons/visualize-in-wandb-logo.svg'

import "./styles.css";
import { convertToBaseModel } from '@utils/index';

function ClarifierLogsPage() {
  
  const { projectId } = useParams();
  
  const [logs, setLogs] = useState([]);
  
  useEffect(() => {
    
    const fetchLogs = () => {
      if(projectId) {
        getClarifierLogs(projectId)
        .then(resp => {
          console.log(resp)
          setLogs(resp.logs)
        })
        .catch((err: any) => {
          console.log(err)
        })
      }
    }
    
    fetchLogs()
  }, [projectId])
  
  const getLatestModalContainer = (latest_model_value: string) => {
    if(latest_model_value === 'TBU') return 'TBU'
    
    return (
      <a href={latest_model_value} target='_blank' rel="noreferrer">
        <img
          style={{
            width: '150px',
            cursor: 'pointer',
            marginBottom: '-6px'
          }}
          src={VisualizeInWandb}
          alt='visualize-in-wandb-logo'
        />
      </a>
    )
  }
  
  return (
    <div>
      {logs?.map((x: any, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <span style={{fontWeight: 'bold', fontSize: '15px'}}>Round {index + 1}</span>
          </AccordionSummary>
          <AccordionDetails style={{display: 'flex', gap: '12px'}}>
            <div style={{display: 'flex', justifyContent: 'center', gap: '16px'}}>
              <div style={{flex: 1}}>
                <table className='clarifier-logs-table'>    
                  <caption style={{border: '2px solid #0000002e', fontWeight: '900', }}>Settings</caption>
                  <tr className='clarifier-tr'>
                    <th className='clarifier-th'>Name</th>
                    <th className='clarifier-th'>Values</th>
                  </tr>
                  
                  {Object.entries(x.settings).map((sett:any, Isett) => (
                    <tr key={Isett} className='clarifier-tr'>
                      <td className='clarifier-td clarifier-td-name'>{convertToBaseModel(sett[0])}</td>
                      <td className='clarifier-td'>{sett[1].toString()}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <div style={{flex: 1}}>
                <table className='clarifier-logs-table'>    
                  <caption style={{border: '2px solid #0000002e', fontWeight: '900', }}>Round Details</caption>
                  <tr className='clarifier-tr'>
                    <th className='clarifier-th'>Name</th>
                    <th className='clarifier-th'>Values</th>
                  </tr>
                  
                  {Object.entries(x.round_details).map((sett:any, Isett) => (
                    <tr key={Isett} className='clarifier-tr'>
                      <td className='clarifier-td'>{convertToBaseModel(sett[0])}</td>
                      <td className='clarifier-td'>
                        {sett[0] === 'latest_model' 
                        ?  getLatestModalContainer(sett[1])
                        : sett[1]
                        }
                      </td>
                    </tr>
                  ))}
                </table>
              </div>  
            </div>
            
          </AccordionDetails>
      </Accordion>  
      ))}
      
    </div>
  )
}

export default ClarifierLogsPage