import { Tooltip } from "@mui/material";
import { EllipsisMainWrapper } from "./style";

interface EllipsisProps {
  children: React.ReactNode;
  width?: number;
  isTooltip?: boolean;
}

function Ellipsis(props: EllipsisProps) {
  const { children, width, isTooltip } = props;

  return isTooltip ? (
    <Tooltip title={children} enterTouchDelay={0}>
      <EllipsisMainWrapper width={width || 100}>{children}</EllipsisMainWrapper>
    </Tooltip>
  ) : (
    <EllipsisMainWrapper width={width || 100}>{children}</EllipsisMainWrapper>
  );
}

Ellipsis.defaultProps = {
  width: 100,
  isTooltip: false,
};

export default Ellipsis;
