import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '2rem',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffffff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: '2rem',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#ff0000', marginBottom: '1rem' }}>
          404
        </Typography>
        <Typography variant="h4" sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1.5rem' }}>
          Oops! Page Not Found
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
          The page you are looking for does not exist.
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/projects"
          sx={{
            backgroundColor: '#3f51b5',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#2c3e99',
            },
          }}
        >
          Go to Homepage
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
