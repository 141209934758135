import { useState } from "react";
import { Box, Button } from '@mui/material'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { FormikProps } from 'formik'
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';

interface IUpload {
    handleAction: any,
    handleNext?: any,
    handleBack?: any,
    formik: FormikProps<any>
}

export default function UploadUnlabelledSetForm(props: IUpload) {
    const { handleNext, formik } = props;
    const [files, setFiles] = useState<File[]>([]);
    const { errors, handleSubmit: handleSub, isSubmitting, touched } = formik || {}

    const handleSetFiles = (fs: File[]) => {
        setFiles(fs);
        formik.setFieldValue('compressed_file', fs[0])
    }

    const handleUrl = (url: string) => {
        files.length > 0 && setFiles([]);
        formik.setFieldValue('compressed_file', url)
    }

    return (
        <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
            <Box>
                <CustomFileUpload files={files} setFiles={handleSetFiles} />
                <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />
                <input id="file" name="compressed_file" type="file" hidden />
                <h4>Or</h4>
                <CustomTextField
                    placeholder="Input File link"
                    sx={{ mb: 2 }}
                    fullWidth
                    name="compressed_file"
                    value={files.length === 0 ? formik.values.compressed_file : ''}
                    onChange={(e) => handleUrl(e.target.value)}
                />
                <Box sx={{ mb: 2 }}>
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {`Upload`}
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => handleNext()}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {`Skip`}
                        </Button>
                    </div>
                </Box>
            </Box>
        </form>
    )
}