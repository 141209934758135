import React, { memo } from 'react'

import { TailSpin } from 'react-loader-spinner';

import './styles.scss'

interface ICircularSpinnerWithNumberInside {
  number?: number
}

const CircularSpinnerWithNumberInside = ({ number }: ICircularSpinnerWithNumberInside) => {
  return (
    <div className="spinner-container">
      <div className="spinner-overlay">
        <TailSpin height={35} width={35} color={'#ED8622'} />
        <span className="static-number">{number}</span>
      </div>
    </div>
  );
}

export default memo(CircularSpinnerWithNumberInside) 
