import { OutlinedInputProps } from '@mui/material'
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControl
} from '@mui/material';

// assets
import EyeShow from '@icons/EyeShowIcon/index';
import EyeClose from '@icons/EyeCloseIcon/index';

interface IPassword {
  showPassword: boolean,
  handleClickShowPassword: any,
  handleMouseDownPassword: any
}
function CustomPasswordField(props: IPassword & OutlinedInputProps) {
  const { showPassword, handleClickShowPassword, handleMouseDownPassword, ...textFieldProps } = props;
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel htmlFor="password-login">Password</InputLabel>
      <OutlinedInput
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {showPassword ? <EyeShow /> : <EyeClose />}
            </IconButton>
          </InputAdornment>
        }
        placeholder="Enter password"
        label="password"
        {...textFieldProps}
      />
    </FormControl>
  )
}

export default CustomPasswordField