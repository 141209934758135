import { TextField, TextFieldProps } from '@mui/material'

function CustomEmailField(props: TextFieldProps) {
  return (
    <TextField
      type='email'
      fullWidth
      placeholder="Enter email address"
      {...props}
    />
  )
}

export default CustomEmailField