import services from "..";
import { projectApiEntryPoint } from '@services/apiEndpoints';
import { ICreateProject, ICreateLabels, IUploadLabelledFile } from './types';
import { showErrorSnackbar } from '@components/shared-layouts/Snackbar/helper'
import { IProjectContext } from '@context/Project/projectContext';

// get projects api
export const getProjects = async (data: any, projectContextInfo?: IProjectContext) => {
    try {
        const res = await services.get(projectApiEntryPoint().getProjects);
        return res;
    } catch (error) {
        throw error;
    }
}

// create project api
export const createProject = async (data: ICreateProject, projectContextInfo?: IProjectContext) => {
    try {
        const payload = {
            'project_type': data.projectType,
            'project_name': data.projectName
        }
        const res = await services.post(projectApiEntryPoint().createProject, payload);
        return res;
    } catch (error) {
        throw error;
    }
}

// Delete Project Api
export const deleteProject = async (projectId: any) => {
    try {
        const res: any = await services.delete(`/projects/${projectId}`);
        return res?.data;
    } catch (error) {
        throw error;
    }
}

// create labels api
export const createLabels = async (data: ICreateLabels, projectContextInfo: IProjectContext) => {
    console.log('data received in create labels', data)
    try {
        // const payload = {
        //     'classes': typeof (data.labels) === 'string' ? data.labels?.split(',') ?? [] : data.labels
        // }
        const res = await services.post(projectApiEntryPoint(projectContextInfo.state?.projectId).createLabels, data.labels);
        return res;
    } catch (error) {
        throw error;
    }
}

export const deleteLabels = async (data: ICreateLabels, projectContextInfo: IProjectContext) => {
    console.log('data received in create labels', data)
    try {
        const payload = {
            'classes': typeof (data.labels) === 'string' ? data.labels?.split(',') ?? [] : data.labels
        }
        const res = await services.delete(projectApiEntryPoint(projectContextInfo.state?.projectId).deleteLabels, payload);
        return res;
    } catch (error) {
        throw error;
    }
}

export const getLabels = async (projectId: any) => {
    try {
        const res: any = await services.get(`/projects/${projectId}/labels`);
        return res?.data;
    } catch (error) {
        throw error;
    }
}

export const getLabelsV2 = async (projectId: any) => {
    try {
        const res: any = await services.get(`/projects/v2/${projectId}/labels`);
        return res?.data;
    } catch (error) {
        throw error;
    }
}

export const updateLabelsV2 = async (data: any, projectContextInfo: IProjectContext) => {
    console.log('data received in update labels', data)
    try {
        // const payload = {
        //     'classes': typeof (data.labels) === 'string' ? data.labels?.split(',') ?? [] : data.labels
        // }
        const res = await services.post(projectApiEntryPoint(projectContextInfo.state?.projectId).updateLabels, data.labels);
        return res;
    } catch (error) {
        throw error;
    }
}

// upload  unlabelled set api
export const uploadUnlabelledSet = async (data: IUploadLabelledFile, projectContextInfo?: IProjectContext) => {
    let formPayload = new FormData();
    formPayload.append('compressed_file', data.compressed_file);
    formPayload.append('project_id', `${projectContextInfo?.state?.projectId}`);
    let addHeaders = {
        'Content-Type': 'multipart/form-data'
    }
    try {
        const res = await services.post(projectApiEntryPoint().uploadUnlabelledSet, formPayload, undefined, null, addHeaders);
        return res;
    } catch (error) {
        throw error;
    }
}

// upload  Labelled set api
export const uploadLabelledSet = async (data: any, projectContextInfo?: IProjectContext) => {
    try {
        let formPayload = new FormData();
        formPayload.append('compressed_file', data.compressed_file);
        formPayload.append('eval_ratio', data.eval_ratio);
        formPayload.append('project_id', `${projectContextInfo?.state?.projectId}`);
        let addHeaders = {
            'Content-Type': 'multipart/form-data'
        }
        const res = await services.post(projectApiEntryPoint().uploadLabelledSet, formPayload, undefined, null, addHeaders);
        return res;
    } catch (error) {
        throw error;
    }
}

const ProjectService = async (apiRouteStr: String, data: any = {}, projectContextInfo: IProjectContext) => {
    let apiToCall;
    switch (apiRouteStr) {
        case 'getProjects':
            apiToCall = getProjects;
            break;
        case 'createProject':
            apiToCall = createProject;
            break;
        case 'createLabels':
            apiToCall = createLabels;
            break;
        case 'uploadUnlabelledSet':
            apiToCall = uploadUnlabelledSet;
            break;
        case 'uploadLabelledSet':
            apiToCall = uploadLabelledSet;
            break;
        default:
            apiToCall = getProjects
    }
    try {
        const res: any = await apiToCall(data, projectContextInfo);
        return res;
    } catch (e: any) {
        showErrorSnackbar(e?.error?.message ?? 'Api could not be called')
        throw e;
    }

}

export default ProjectService;