import React from "react";
import ImageClassificationDataLabellingPage from "../ImageClassificationDataLabellingPage";
import ObjectDetectionDataLabellingPage from "../ObjectDetectionDataLabellingPage";
import SegmentationDataLabellingPage from "../SegmentationDataLabellingPage";
import { useParams } from "react-router-dom";

const DataLabellingSelectionPage = () => {
  
  const { project_type } = useParams();
  
  const getPage = () => {
    switch (project_type) {
      case "object_detection":
        return <ObjectDetectionDataLabellingPage />;
      case "image_classification":
        return <ImageClassificationDataLabellingPage />;
      case "segmentation":
        return <SegmentationDataLabellingPage />;
      case "object_detection_-_(clarifier)":
        return <ObjectDetectionDataLabellingPage />;
      case "image_classification_-_(clarifier)":
        return <ImageClassificationDataLabellingPage />;
    }
  };

  console.log("tempprojectype from the data page", project_type);
  // return tempProjectTypePath === "object_detection" ? (
  //   <ObjectDetectionDataLabellingPage />
  // ) : (
  //   <ImageClassificationDataLabellingPage />
  // );

  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default DataLabellingSelectionPage;
