import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, SelectProps } from '@mui/material'

export interface dropdownDataType {
  value: string,
  label: string
}

export interface CustomDropdownFieldProps extends SelectProps {
  dropdownData: dropdownDataType[],
  formik: any
}

function CustomDropdownField(props: CustomDropdownFieldProps) {
  const { dropdownData, formik, value, ...otherProps } = props
  const { touched, errors } = formik

  return (
    <FormControl style={props.style} sx={{ mb: 2, minWidth: 120 }}>
      <InputLabel
        id="demo-simple-select-helper-label"
        error={Boolean(touched[props.name as string] && errors[props.name as string])}
      >
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value ?? ''}
        {...otherProps}
      >
        {dropdownData.map((y: dropdownDataType) => (
          <MenuItem value={y.value} key={y.value}>{y.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default React.memo(CustomDropdownField)