/* eslint-disable react-hooks/exhaustive-deps */
import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper'
import { deleteInferenceFileFromGallery, getInferenceDownloads } from '@services/dashboardService'
import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Dialog } from '@mui/material';
import axios from 'axios';
import { CookieHelper } from '@utils/cookieHelper'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { saveAs } from 'file-saver'
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import CircularProgressWithLabel from '@components/shared-ui-components/CircularProgressWithLabel';


interface ILog {
  imageId: string,
}

function ViewInferenceLogsPage({ download_id }: { download_id: string }) {

  const [downloadProgress, setDownloadProgress] = useState<{ progress: number, imageId: string | undefined }>({ progress: 0, imageId: undefined })

  const [loading, setLoading] = useState<boolean>(false)

  const columns = useMemo<MRT_ColumnDef<ILog>[]>(
    () => [
      {
        accessorKey: 'imageId',
        header: 'ImageId',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }: any) => <>
          <p key={cell.getValue()} style={{ clear: 'both', whiteSpace: 'nowrap', display: 'inline-block' }}>{cell.getValue()}</p>
        </>
      },
      {
        accessorKey: 'imageId',
        header: 'Actions',
        Cell: ({ cell }: any) => <>
          <Button key={cell.getValue()} variant='outlined' onClick={() => showSelectedInferenceImage(cell.getValue())} sx={{ mt: 2 }}> <CloudDownloadIcon color='success' /> </Button>
          <Button key={cell.getValue()} style={{ marginLeft: '8px' }} variant='outlined' onClick={() => deleteSelectedInferenceImage(cell.getValue())} sx={{ mt: 2 }}> <DeleteIcon color='error' /> </Button>
        </>
        // size: 140
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );


  const formatLogs = (logs: any) => {
    let _logs: ILog[] = [];
    const currentLogs = logs;
    if (currentLogs?.length > 0) {

      currentLogs?.forEach((it: string, idx: any) => {
        let item = {
          key: `${it}-${idx}`,
          imageId: it
        }
        _logs.push(item)
      });

    }
    setInferenceData(_logs);
    setLoading(false)
  }

  const { getItem } = CookieHelper();

  const { projectId } = useParams()

  const [inferenceData, setInferenceData] = useState<ILog[]>()

  const deleteSelectedInferenceImage = (imageId: string) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      !confirm(`Are you sure you want to delete ${imageId}`)
    ) {
      return;
    }
    deleteInferenceFileFromGallery(projectId as string, download_id, imageId)
      .then(resp => {
        getInferenceDownloads(projectId as string, download_id)
          .then(resp => {
            formatLogs(resp.files)
          })
          .catch(e => { console.log(e); showErrorSnackbar(e.error.message) })
        showSuccessSnacbar('Image has been deleted')
      })
      .catch(err => showErrorSnackbar('Something went wrong'))
  }

  const showSelectedInferenceImage = (imageId: string) => {

    const imageUrl = `https://genie-api.avawatz.net/api/operations/inference/logs/${projectId}/${download_id}/${imageId}`;
    const userToken: any = getItem("token") || ""

    setDownloadProgress({ progress: 0, imageId })

    axios({
      url: imageUrl,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      onDownloadProgress: (progressEvent) => {
        // Update the progress bar based on the progress event
        const { total = 0, loaded } = progressEvent
        const progressPercentage = Math.round((loaded / total) * 100);
        setDownloadProgress({ progress: progressPercentage, imageId })
      },
    })
      .then((response) => {
        const imageUrlObject = URL.createObjectURL(response.data);
        // setSelectedImageIdData(imageUrlObject);
        saveAs(imageUrlObject, imageId)
        setDownloadProgress({ progress: 0, imageId: undefined })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  useEffect(() => {
    setLoading(true)
    getInferenceDownloads(projectId as string, download_id)
      .then(resp => {

        formatLogs(resp.files)
        console.log('inference data', resp.files)
      })
      .catch(e => { console.log(e); showErrorSnackbar(e.error.message) })
  }, [])

  return (
    <div style={{ padding: '10px' }}>
      <h3>View or Download Inference</h3>

      {inferenceData && inferenceData.length > 0 ?
        <div style={{ maxHeight: '70vh', overflow: 'scroll' }}>
          <MaterialReactTable
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center',
                },
                // size: 120,
              },
            }}
            muiTablePaperProps={{ style: { width: '100%' } }}
            columns={columns}
            data={inferenceData}
            enableColumnOrdering
            positionActionsColumn="last"
            key={Math.random()}
            state={{ isLoading: loading }}
          />
        </div>

        : <div>Loading ...</div>

      }

      <Dialog open={downloadProgress.imageId !== undefined}>
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h4>Download in Progress</h4>
          <CircularProgressWithLabel value={downloadProgress.progress} />
        </div>
      </Dialog>
    </div>
  )
}



export default ViewInferenceLogsPage