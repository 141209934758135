
// material-ui
import { Grid, Stack } from '@mui/material';

// project import
import { CustomLinkButton } from '@components/shared-ui-components/Buttons';
import { TypographyH2 } from '@components/shared-ui-components/CustomTypography';
import AuthForm from '@components/shared-ui-components/AuthForm';

// ================================|| REGISTER ||================================ //

const Register = () => (

  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
        <TypographyH2>Sign up</TypographyH2>
        <CustomLinkButton label="Already have an account?" linkTo='/auth/login' />
      </Stack>
    </Grid>
    <Grid item xs={12}>
      <AuthForm page={'register'} />
    </Grid>
  </Grid>
);

export default Register;
