import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Colors from '@constants/Colors';
// material-ui
import { Box } from '@mui/material';

// project import
import { Card } from '@mui/material';
// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

interface IAuthCard {
  children?: any,
}

const AuthCard = ({ children }: IAuthCard) => (
  <Card
    sx={{
      maxWidth: { xs: 400, lg: 475 },
      margin: { xs: 2.5, md: 3 },
      background: Colors.neutralwhite_10,
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      }
    }}
  >
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
      <Outlet />
    </Box>
  </Card>
);

AuthCard.propTypes = {
  children: PropTypes.node
};

export default AuthCard;
