import { FormHelperText } from '@mui/material'
import { FormikErrors } from 'formik'

interface IErrorHelper {
    isError: boolean,
    errorMsg: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined,
    helperId: string,
}

ErrorHelperWrapper.defaultProps = {
    errorMsg: '',
}

function ErrorHelperWrapper(props: IErrorHelper) {
    const { isError, errorMsg, helperId } = props;
    return (
        <>
            {isError && (
                <FormHelperText error id={`helper-text-${helperId}`} style={{ display: 'block', width: '100%' }}>
                    {errorMsg?.toString()}
                </FormHelperText>
            )}
        </>
    )
}

export default ErrorHelperWrapper