import styled from "styled-components";

export const EllipsisMainWrapper = styled.span<{
  width: number;
}>`
  max-width: ${(props: any) => props.width}px;
  width: 100vw;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
