import { createContext } from "react";
import { IAnnotation } from '@services/dashboardService/types'

export interface IProjectState {
    projectId: string | number,
    projectName?: string,
    projectType?: string,
    createTime?: string,
    labels?: string[],
    imageIds?: string[],
    prev_annotations?: IAnnotation[],
    updated_annotations?: IAnnotation[],
    processId?: string,
    src_dataset?: string,
}

export interface IProjectContext {
    state: IProjectState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState: any,
}

const initialProjectContext = {
    state: {
        projectId: -1,
        labels: [],
        imageIds: [],
        src_dataset: 'unlabelled_set'
    },
    setState: () => { }
}

const ProjectContext = createContext<IProjectContext>(initialProjectContext)

export default ProjectContext;