const Colors = {
  black: "#000000",
  white: "#FFFFFF",
  grey: '#0F0F0F',
  
  info_600: "#1570EF",

  neutralblack: "#21263B",
  neutralwhite: "#F2F3F7",
  neutralwhite_10: '#F3F3F3',
  neutralwhite_50: "#f8f8fb",
  neutral_gray_100: "#eaecf5",
  neutral_gray_200: "#C8CCE5",
  neutral_gray_300: "#D0D2DD",
  neutral_gray_400: "#989DB3",
  neutral_gray_500: "#666C85",
  neutral_gray_900: "#21263B",

  primary: "#4E5BA6",
  primary_600: "#3E4784",
  primary_700: "#363f72",
  primary_800: "#293056",
  primary_900: "#101323",

  primarygray_200: "#E4E6EC",
  primarygray_300: "#9EA5D1",
  primarygray_400: "#989DB3",

  primaryred: "#F04438",

  secondary: "#3E4784",

  secondaryorange_500: "#FB6514",

  secondarypurple: "#6938EF",
  secondarypurple_35: "#7A5AF8",
  secondarypurple_66: "#BDB4FE",
  secondarypurple_97: "#D9D6FE",
  secondarypurple_100: "#EBE9FE",
  secondarypurple_500: "#7A5AF8",
  secondarypurple_600: "#6938EF",
  secondarypurple_700: "#5925DC",

  secondarypink_100: "#FCE7F6",
  secondarypink_600: "#DD2590",
  secondarypink_500: "#EE46BC",

  secondaryred: "#fee4e2",
  secondaryred_50: "#FEF2F3",
  secondary_rose_100: "#FFE4E8",
  secondary_rose_500: "#F63D68",
  secondary_blue_100: "#E0F2FE",
  secondary_blue_600: "#0086C9",
  secondary_orange_500: "#FB6514",
  secondary_orange_100: "#FFEAD5",

  warning_600: "#DC6803",
  warning_100: "#FEF0C7",
  warning_500: "#F79009",

  success_50: "#ECFDF3",
  success_500: "#12B76A",
  success_600: " #039855",

  error_200: "#FECDCA",
  error_600: "#D92D20",
  error_500: "#F04438",

  Elevation_2: `0px 1px 3px rgba(33, 38, 59, 0.1),
  0px 1px 2px rgba(33, 38, 59, 0.06)`,
  Elevation_4: `0px 12px 16px -4px rgba(33, 38, 59, 0.1),
  0px 4px 6px -2px rgba(33, 38, 59, 0.05)`,
  Elevation_3: `0px 4px 8px -2px rgba(33, 38, 59, 0.1), 0px 2px 4px -2px rgba(33, 38, 59, 0.06)`,
};

export default Colors;
