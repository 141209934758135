import React from 'react'
import { SnackbarProvider } from 'notistack'
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

const Snackbar = (props: { children: React.ReactNode }) => {
  const { children } = props

  function transition(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={transition}
      transitionDuration={800}
      autoHideDuration={3500}
      maxSnack={3}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  )
}

export default Snackbar