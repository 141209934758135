import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'
import React from 'react'

interface CustomExportFormatFieldProps extends SelectProps {
  formik: any
}

function CustomExportFormatField(props: CustomExportFormatFieldProps) {

  const datasetList = [
    {
      label: 'Coco',
      value: 'COCO'
    },
    {
      label: 'Pascal Voc',
      value: 'PASCAL_VOC'
    },
    {
      label: 'Yolo',
      value: 'YOLO'
    },
    {
      label: 'Genie',
      value: 'GENIE'
    }
  ]

  return (
    <CustomDropdownField
      dropdownData={datasetList}
      {...props}
    />
  )
}

export default React.memo(CustomExportFormatField)