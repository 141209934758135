import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'
import React from 'react'
import { useParams } from 'react-router-dom'

interface CustomUnLabelledDataSetFieldProps extends SelectProps {
  formik: any
  page?: string
}

function CustomUnLabelledDataSetField(props: CustomUnLabelledDataSetFieldProps) {
  
  const {project_type} = useParams()

  const unLabelledDatasetList = [
    {
      label: 'Unlabelled Set',
      value: 'unlabelled_set'
    },
    {
      label: 'Diverse Selection Set',
      value: 'diverse_selected_set'
    },
    {
      label: 'Active Selection Set',
      value: 'active_selected_set'
    },
    {
      label: 'Target Selection Set',
      value: 'target_selected_set',
    }
  ]
  
  const clarifierUnLabelledDatasetList = [
    {label: 'Unlabelled Set', value: 'unlabelled_set'},
    {label: "Initial Selected Set", value: "initial_selected_set"},
    {label: "Hard Selected Set", value: "hard_selected_set"},
    {label: "Medium Selected Set", value: "medium_selected_set"},
    {label: "Easy Selected Set", value: "easy_selected_set"}
  ]

  var variables: any = {
    'diverseSelection': 1,
    'activeSelection': 2,
    'targetSelection': 3
  };

  console.log('props from the unlabelled dataset field', props)

  const { page } = props

  const indexToRemove = page && variables[page]

  let filteredUnlabelledList = page
    ? unLabelledDatasetList.filter(function (_, index) {
      return index !== indexToRemove;
    })
    : unLabelledDatasetList

  
  if(project_type === 'image_classification_-_(clarifier)' && page === 'diverseSelection') { 
    filteredUnlabelledList = [ {label: 'Unlabelled Set', value: 'unlabelled_set'}]
  }
  
  if(project_type === 'object_detection_-_(clarifier)' && page === 'diverseSelection') { 
    filteredUnlabelledList = [ {label: 'Unlabelled Set', value: 'unlabelled_set'}]
  }
  
  if(project_type === 'instance_segmentation_-_(clarifier)' && page === 'diverseSelection') { 
    filteredUnlabelledList = [ {label: 'Unlabelled Set', value: 'unlabelled_set'}]
  }
  
  if(project_type === 'image_classification_-_(clarifier)' && page === 'autoLabelling') { 
    filteredUnlabelledList = clarifierUnLabelledDatasetList
  }
  
  if(project_type === 'object_detection_-_(clarifier)' && page === 'autoLabelling') { 
    filteredUnlabelledList = clarifierUnLabelledDatasetList
  }
  
  if(project_type === 'instance_segmentation_-_(clarifier)' && page === 'autoLabelling') { 
    filteredUnlabelledList = clarifierUnLabelledDatasetList
  }


  console.log('filtered unlabelled list', filteredUnlabelledList)

  return (
    <CustomDropdownField
      dropdownData={filteredUnlabelledList}
      {...props}
    />
  )
}

export default React.memo(CustomUnLabelledDataSetField)