import { useState } from "react";
import { Box, Button } from '@mui/material'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { FormikProps } from "formik";
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { TextField } from '@mui/material'

interface IUpload {
    handleAction?: any,
    handleNext?: any,
    handleBack?: any,
    formik: FormikProps<any>
}

export default function UploadLabelledSetForm(props: IUpload) {
    const { handleNext, formik } = props;
    const [files, setFiles] = useState<File[]>([]);
    const { errors, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}
    const { eval_ratio } = values

    const handleSetFiles = (fs: File[]) => {
        setFiles(fs);
        formik.setFieldValue('compressed_file', fs[0])
    }

    const handleUrl = (url: string) => {
        files.length > 0 && setFiles([]);
        formik.setFieldValue('compressed_file', url)
    }

    const handleEvalRatioChange = (value: any) => {
        formik.setFieldValue('eval_ratio', value)
    };

    return (
        <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
            <Box>
                <div style={{ border: '1px solid black', padding: '12px' }}>
                    <CustomFileUpload files={files} setFiles={handleSetFiles} />
                    <input id="file" name="compressed_file" type="file" hidden />
                    <h4>Or</h4>
                    <CustomTextField
                        placeholder="Input File link"
                        sx={{ mb: 2 }}
                        fullWidth
                        name="compressed_file"
                        value={files.length === 0 ? formik.values.compressed_file : ''}
                        onChange={(e) => handleUrl(e.target.value)}
                    />
                </div>
                <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />

                <TextField
                    sx={{ mb: 2, mt: 2 }}
                    type='number'
                    name="eval_ratio"
                    placeholder="Ratio"
                    value={eval_ratio}
                    onChange={(e) => handleEvalRatioChange(e.target.value)}
                    InputProps={{
                        inputProps: { min: 0, max: 1, step: 0.05 }
                    }}

                />
                <ErrorHelperWrapper isError={!!(touched.eval_ratio && errors.eval_ratio)} errorMsg={errors.eval_ratio} helperId='eval_ratio-create-project' />
                <Box sx={{ mb: 2 }}>
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {`Upload`}
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {`Skip`}
                        </Button>
                    </div>
                </Box>

            </Box>
        </form>
    )
}