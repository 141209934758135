
import * as Yup from 'yup';
import { NavigateFunction } from 'react-router-dom';

import { registerService, loginService } from '@services/userService';

export const AuthFormJson: any = {
    login: [
        { name: 'username', type: 'string', defaultValue: '' },
        { name: 'password', type: 'string', defaultValue: '' },
    ],
    register: [
        { name: 'username', type: 'string', defaultValue: '' },
        { name: 'email', type: 'email', defaultValue: '' },
        { name: 'wandb_token', type: 'string', defaultValue: '' },
        { name: 'password', type: 'string', defaultValue: '' },
    ],
    forgetPassword: [
        { name: 'email', type: 'email', defaultValue: '' },
    ]
}

export const getAuthFormInitialValues = (page: string) => {
    let initialValues: any = {};
    AuthFormJson[page]?.map((x: any) => {
        if (x.type === 'string' || x.type === 'email') {
            return initialValues[x.name] = x.defaultValue || '';
        }
        return initialValues[x.name] = x.defaultValue;
    })
    return initialValues;
}

export const authFormValidationSchema: any = {
    login: Yup.object().shape({
        // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        username: Yup.string().max(255).required('Username is required'),
        password: Yup.string().max(255).required('Password is required')
    }),
    register: Yup.object().shape({
        username: Yup.string().max(255).required('User Name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        wandb_token: Yup.string().max(255).required('WandB token is required'),
        password: Yup.string().max(255).required('Password is required')
    }),
    forgetPassword: Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    }),
}

export const callAuthFormApi = (page: string) => {
    switch (page) {
        case 'register':
            return registerService
        case 'login':
            return loginService
        default:
            return registerService
    }
}

export const getActionAfterSubmit = (page: string, navigate: NavigateFunction) => {
    switch (page) {
        case 'register':
            return () => navigate('/auth/login')
        case 'login':
            return () => navigate('/projects')
        default:
            return () => navigate('/projects')
    }
}