import { Button } from '@mui/material'

interface ISubmitButton {
    btnName?: string,
    btnAction?: any,
    isSubmitting?: boolean,
    style?: any
}

CustomActionButton.defaultProps = {
    btnName: 'Start',
    btnAction: () => { },
    isSubmittin: false,
}

function CustomActionButton(props: ISubmitButton) {
    const { btnAction, btnName, isSubmitting, style } = props;
    return (
        <Button disableElevation disabled={isSubmitting} size="medium" onClick={btnAction} type="submit" variant="contained" color="primary" style={style}>
            {btnName}
        </Button>
    )
}

export default CustomActionButton;