import React, { useEffect, useState } from 'react';
import { Chip, Grid, Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { getLabelsV2 } from '@services/projectService';
import { useParams } from 'react-router-dom';
import { showErrorSnackbar } from '@components/shared-layouts/Snackbar/helper';

import ColorPicker from 'react-pick-color';

import { Dialog } from '@mui/material';


interface ILabelsNew {
  classes: string[]
  keys: string[]
  colors: string[]
}

interface ICreateLabel {
  handleAction?: any,
  handleNext?: any,
  handleBack?: any,
  formik: FormikProps<any>
  setTempAddedLabels?: any
  setTempDeletedLabels?: any
  tempAddedLabels?: ILabelsNew
  tempDeletedLabels?: string[]
  tempUpdateLabels?: ILabelsNew
  setTempUpdateLabels?: any
}



const CreateLabelsForm = (props: ICreateLabel) => {
  const {
    formik,
    handleNext,
    setTempAddedLabels,
    setTempDeletedLabels,
    tempAddedLabels,
    tempDeletedLabels,
    tempUpdateLabels,
    setTempUpdateLabels
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [labels, setLabels] = useState('');
  const [chips, setChips] = useState<string[]>([]);
  const { projectId } = useParams()

  const [formattedDataFromV2Api, setFormattedDataFromV2Api] = useState<any>()

  const { errors, handleSubmit: handleSub, isSubmitting, touched } = formik || {}

  const [focusedChip, setFocusedChip] = useState<any>()
  const [color, setColor] = useState('')
  const [shortcutKey, setShortcutKey] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      addChip();
    }
  };

  // Need to do the changes in the add chip Method
  const addChip = () => {
    const trimmedValue = inputValue.trim()
    console.log('input trimmeed value', trimmedValue)
    if (trimmedValue !== '' && chips.indexOf(trimmedValue) === -1) {
      const _chips = [...chips, trimmedValue]
      setChips(_chips);
      setLabels(_chips.join(','))
      formik.setFieldValue('labels', _chips.join(','))
      setInputValue('');
      console.log('test', setTempAddedLabels)
      if (setTempAddedLabels) {

        const tempAddedLabelsNewObj = {
          classes: [...tempAddedLabels?.classes || [], trimmedValue],
          keys: [...tempAddedLabels?.keys || [], null],
          colors: [...tempAddedLabels?.colors || [], null]
        }

        setTempAddedLabels((prevState: ILabelsNew) => tempAddedLabelsNewObj)
      }
    } else if (trimmedValue !== '') {
      showErrorSnackbar(`${trimmedValue} is already present in labels`)
    }
  };

  const handleDeleteChip = (chipToDelete: string) => () => {
    const _chips = chips.filter(chip => chip !== chipToDelete);
    setChips(_chips);
    formik.setFieldValue('labels', _chips.join(','))

    // Means the user hasn't save the chip in backend and is directly deleting from frontend
    const indexOfDeletedChipInTempAddedLabels = tempAddedLabels?.classes.indexOf(chipToDelete)
    if (indexOfDeletedChipInTempAddedLabels !== -1) {
      let updatedTempAddedLabels: any = tempAddedLabels
      for (const key in updatedTempAddedLabels) {
        if (Array.isArray(updatedTempAddedLabels[key])) {
          updatedTempAddedLabels[key].splice(indexOfDeletedChipInTempAddedLabels, 1);
        }
      }
      setTempAddedLabels(updatedTempAddedLabels)
    }

    setTempAddedLabels && setTempDeletedLabels((prevState: any) => [...prevState, chipToDelete])
    setLabels(_chips.join(','))

    console.log('final', tempAddedLabels, tempDeletedLabels, tempUpdateLabels)
  };

  const handleFocusChip = (chip: string, index: any) => {
    console.log('Handle focus chip', chip, index, formattedDataFromV2Api)

    setColor(formattedDataFromV2Api && formattedDataFromV2Api[index]?.colors)
    setShortcutKey(formattedDataFromV2Api && formattedDataFromV2Api[index]?.keys)
    setFocusedChip(chip)
  }

  const getLabelsFromBackend = () => {
    getLabelsV2(projectId).then(resp => {
      console.log('called the get labels api', resp.labels)
      setChips(resp.labels.classes);
      setLabels(resp.labels.classes.join(','))
      formik.setFieldValue('labels', resp.labels.classes.join(','))

      const formattedApiData: any = []

      const rawApiData = resp.labels

      for (let i = 0; i < rawApiData.classes?.length; i++) {
        const newObj = {
          classes: rawApiData.classes[i],
          keys: rawApiData?.keys?.[i] ?? null,
          colors: rawApiData?.colors?.[i] ?? null
        };
        formattedApiData.push(newObj);
      }

      setFormattedDataFromV2Api(formattedApiData)

    })
      .catch(e => {
        console.log(e)
      })
  }

  const getDisabledStatus = () => {
    console.log(' lenghts ', tempAddedLabels?.classes.length, tempDeletedLabels?.length)

    if (tempAddedLabels?.classes.length === 0 && tempDeletedLabels?.length === 0 && tempUpdateLabels?.classes.length === 0) {
      return true
    }


    if (isSubmitting) {
      return true
    }

  }

  console.log('tempAddedState ===>', tempAddedLabels)
  console.log('temp update state ===>', tempUpdateLabels)

  const onColorAndShortcutSaveClick = () => {
    // Over here we can directly call the Update api 
    console.log('save the color', tempAddedLabels)

    // We will also have to check if this current forcused chip is there in the temp Added Object

    // If not present then it will go in temp added labels

    const indexOfPresentLabelInTempAdded: any = tempAddedLabels?.classes.indexOf(focusedChip as never)

    console.log('indexOfPresentLabelInTempAdded', indexOfPresentLabelInTempAdded)


    // The Error is happening because the user is just creating the 
    // label and without saving the label the user is trying to assign a shortcut key and color
    // We weill need to fix this by doing the code changes in the if block

    if (indexOfPresentLabelInTempAdded !== -1) {
      console.log('coming to the if')
      let tempPresentLabelObj: any = tempAddedLabels

      tempPresentLabelObj.keys[indexOfPresentLabelInTempAdded] = shortcutKey
      tempPresentLabelObj.colors[indexOfPresentLabelInTempAdded] = color

      setTempUpdateLabels(tempPresentLabelObj)

      setFocusedChip(undefined)
      setColor('')
      setShortcutKey('')


    } else {
      console.log('COming to the else')
      // Means the user is saving colors of all ready saved label
      const tempUpdateObj = {
        classes: [...tempUpdateLabels?.classes || [], focusedChip],
        keys: [...tempUpdateLabels?.keys || [], shortcutKey],
        colors: [...tempUpdateLabels?.colors || [], color]
      }

      setTempUpdateLabels(tempUpdateObj)

      setFocusedChip(undefined)
      setColor('')
      setShortcutKey('')

    }
  }

  const getChipBackgroundColor = (chip: string, indexOfChpWhileRender: any) => {

    // First we will check in the temp updated labels becuase the user could have updated the color
    const indexOfChipInTempUpdatedLabels = tempUpdateLabels?.classes.indexOf(chip)
    const indexOfChipInTempAddedLabels = tempAddedLabels?.classes.indexOf(chip)

    // Means there is a chip in the updated labels
    if (indexOfChipInTempUpdatedLabels !== -1) return tempUpdateLabels?.colors[indexOfChipInTempUpdatedLabels as number]

    // Now we will check in the added, Means the user could have just added it 
    if (indexOfChipInTempAddedLabels !== -1) return tempAddedLabels?.colors[indexOfChipInTempAddedLabels as number]

    // Now the has not added or edit so will be from the data base only
    return formattedDataFromV2Api[indexOfChpWhileRender]?.colors
  }

  const getChipShortcutKey = (chip: string, indexOfChpWhileRender: any) => {

    // First we will check in the temp updated labels becuase the user could have updated the color
    const indexOfChipInTempUpdatedLabels = tempUpdateLabels?.classes.indexOf(chip)
    const indexOfChipInTempAddedLabels = tempAddedLabels?.classes.indexOf(chip)

    // Means there is a chip in the updated labels 
    if (indexOfChipInTempUpdatedLabels !== -1) return tempUpdateLabels?.keys[indexOfChipInTempUpdatedLabels as number]

    // Now we will check in the added, Means the user could have just added it 
    if (indexOfChipInTempAddedLabels !== -1) return tempAddedLabels?.keys[indexOfChipInTempAddedLabels as number]

    // Now the has not added or edit so will be from the data base only
    return formattedDataFromV2Api[indexOfChpWhileRender]?.keys
  }

  useEffect(() => {
    // We will check if handle next is present or not 
    console.log('handle next ====.', handleNext)
    !handleNext && getLabelsFromBackend()
    // Call the get labels api 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
      <Grid container spacing={2} style={{ marginInlineStart: 'inherit', width: 'auto' }}>
        <Box sx={{ marginTop: '10px' }}>
          {chips?.map((chip, index) => (
            <>
              <Chip
                key={`${chip}`}
                label={`${chip} - ( ${getChipShortcutKey(chip, index)} )`}
                onDelete={handleDeleteChip(chip)}
                clickable
                onClick={() => handleFocusChip(chip, index)}
                style={{ margin: '5px', backgroundColor: getChipBackgroundColor(chip, index), fontWeight: '900', fontSize: '14px' }}
              />
            </>
          ))}
        </Box>
        <TextField
          sx={{ mt: 2 }}
          label="Add Labels"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder="Type and press enter or comma to add chips"
          fullWidth
        />
        <ErrorHelperWrapper isError={!!(touched.labels && errors.labels)} errorMsg={errors.labels} helperId='labels-create-project' />
        <TextField
          sx={{ mt: 2, display: 'none' }}
          hidden={true}
          label="Add Labels"
          value={labels}
          name="labels"
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder="Type and press enter or comma to add chips"
          fullWidth
        />
        <input type="hidden" name="labels" value={labels} />


        <Dialog open={focusedChip ? true : false} onClose={() => { setFocusedChip(undefined); setColor(''); setShortcutKey('') }}>
          <div style={{ padding: '20px' }}>
            <h4>Please select the color and the shortcut key for labelling the {focusedChip}</h4>
            <ColorPicker
              color={color}
              onChange={color => setColor(color.hex)}
            />
            <TextField
              id="shortcutKey"
              label="Shortcut Key"
              variant="outlined"
              style={{ marginTop: '20px', width: '125px' }}
              inputProps={{ maxLength: 1 }}
              value={shortcutKey}
              onChange={e => setShortcutKey(e.target.value)}
            />
            <br />
            <Button variant='contained' color='primary' style={{ marginTop: '20px' }} onClick={() => onColorAndShortcutSaveClick()}>Save</Button>
          </div>
        </Dialog>

        <Grid>
          {handleNext
            ? <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {`Next`}
                </Button>
                <Button
                  variant='outlined'
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {`Skip`}
                </Button>
              </div>
            </Box>
            : <Button
              variant="contained"
              type="submit"
              disabled={getDisabledStatus()}
              sx={{ mt: 1, mr: 1 }}
            >
              {`Save`}
            </Button>
          }

        </Grid>
      </Grid>

    </form>
  );
};

export default CreateLabelsForm;