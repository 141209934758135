import { createContext } from "react";

export interface LoaderDetailsType {
  loginLoader: boolean;
}

interface InitialStateType {
  loaderState: LoaderDetailsType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loaderSetState: any;
}

const LoaderContext = createContext<InitialStateType[]>([]);

export default LoaderContext;
