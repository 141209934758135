import { SelectProps } from "@mui/material";
import CustomDropdownField from "../../CustomDropdownField";
import React from "react";
import { useParams } from "react-router-dom";

interface CustomDataSetFieldProps extends SelectProps {
  formik: any;
}

function CustomClarifierDataSetField(props: CustomDataSetFieldProps) {

  const {project_type} = useParams()
  
  const imgCls = [
    {
      label: "None",
      value: "",
    },
    {
      label: "Consensus",
      value: "Consensus",
    },
    {
      label: "CLIP",
      value: "CLIP",
    },
  ];
  
  const objDet = [
    {
      label: "None",
      value: "",
    },
    {
      label: "Consensus",
      value: "Consensus",
    },
    {
      label: "SAM",
      value: "SAM",
    },
  ];

  console.log("props from the dataset field", props);
  
  return (
    <CustomDropdownField
      dropdownData={project_type === 'image_classification_-_(clarifier)' ? imgCls : objDet}
      key={Math.random()}
      {...props}
    />
  );
}

export default React.memo(CustomClarifierDataSetField);
