
// material-ui
import { Grid, Stack } from '@mui/material';
import { TypographyH2, TypographyH4 } from '@components/shared-ui-components/CustomTypography';

// project import
import AuthForm from '@components/shared-ui-components/AuthForm';

// ================================|| FORGET PASSWORD ||================================ //

const ForgetPassword = () => (

  <Grid container spacing={3}>
    <Grid item md={12} xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -1.5, sm: 1.5 } }}>
        <TypographyH2>Forgot Password</TypographyH2>
      </Stack>
      <TypographyH4>
        Enter your email and we′ll send you instructions to reset your password
      </TypographyH4>
    </Grid>
    <Grid item xs={12}>
      <AuthForm page={`forgetPassowrd`} />
    </Grid>
  </Grid>
);

export default ForgetPassword;
