import {
  FormHelperText,
  Box,
  Grid
} from '@mui/material';
import { FormikProps } from 'formik'

// custom ui
import { ErrorHelper as ErrorHelperWrapper, CustomSelectProjectTypeField, CustomTextField } from '@components/shared-ui-components/CustomFields';
import { CustomActionButton } from '@components/shared-ui-components/Buttons'

interface ICreateProject {
  handleNext?: any,
  formik: FormikProps<any>
}

function CreateProjectForm(props: ICreateProject) {
  const {formik} = props;
  const { errors, handleBlur, handleChange, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}

  return (
    <>
      <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>

        <CustomSelectProjectTypeField
          formik={formik}
          fullWidth={true}
          id="projectType-create-project"
          value={values.projectType}
          name="projectType"
          onBlur={handleBlur}
          onChange={handleChange}
          error={Boolean(touched.projectType && errors.projectType)}
          label="Project Type"
          placeholder="Select Project Type"
        />
        <ErrorHelperWrapper isError={!!(touched.projectType && errors.projectType)} errorMsg={errors.projectType} helperId='projectType-create-project' />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth={true}
              id="projectName-create-project"
              value={values.projectName}
              name="projectName"
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.projectName && errors.projectName)}
              label="Project Name"
              placeholder="Enter Project Name"
            />
            <ErrorHelperWrapper isError={!!(touched.projectName && errors.projectName)} errorMsg={errors.projectName} helperId='projectName-create-project' />
          </Grid>

          {errors.submit && (
            <Grid item xs={12}>
              <FormHelperText error>{errors.submit.toString()}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <CustomActionButton btnName="Create" isSubmitting={isSubmitting} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default CreateProjectForm;