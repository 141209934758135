// material-ui
import { Grid, Stack } from '@mui/material';

// project import
import CustomLinkButton from '@components/shared-ui-components/Buttons/CustomLinkButton';
import { TypographyH2 } from '@components/shared-ui-components/CustomTypography';
import AuthForm from '@components/shared-ui-components/AuthForm';

// ================================|| LOGIN ||================================ //

const Login = () => (

  <Grid container spacing={3}>
    <Grid item md={12} xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
        <TypographyH2 ellipsis={true}>Login</TypographyH2>
        <CustomLinkButton linkTo='/auth/register' label='Don&apos;t have an account?' />
      </Stack>
    </Grid>
    <Grid item xs={12}>
      <AuthForm page='login' />
    </Grid>
  </Grid>
);

export default Login;
