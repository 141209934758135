import { SelectProps } from '@mui/material'
import CustomDropdownField from '../../CustomDropdownField'

interface CustomCropBlursFieldProps extends SelectProps {
  formik: any
}

function CustomCropBlursField(props: CustomCropBlursFieldProps) {

  const cropBlursList = [
    { label: 'Advanced Blur', value: 'Advanced Blur' },
    { label: 'Blur', value: 'Blur' },
    { label: 'Defocus', value: 'Defocus' },
    { label: 'Gaussian Blur', value: 'Gaussian Blur' },
    { label: 'Glass Blur', value: 'Glass Blur' },
    { label: 'Image Only Transform', value: 'Image Only Transform' },
    { label: 'Median Blur', value: 'Median Blur' },
    { label: 'Motion Blur', value: 'Motion Blur' },
    { label: 'Zoom Blur', value: 'Zoom Blur' },
    { label: 'BBox Safe Random Crop', value: 'BBox Safe Random Crop' },
    { label: 'Center Crop', value: 'Center Crop' },
    { label: 'Crop', value: 'Crop' },
    { label: 'Crop And Pad', value: 'Crop And Pad' },
    { label: 'Crop Non Empty Mask If Exists', value: 'Crop Non Empty Mask If Exists' },
    { label: 'Dual Transform', value: 'Dual Transform' },
    { label: 'Random Crop', value: 'Random Crop' },
    { label: 'Random Crop From Borders', value: 'Random Crop From Borders' },
    { label: 'Random Crop Near BBox', value: 'Random Crop Near BBox' },
    { label: 'Random Resized Crop', value: 'Random Resized Crop' },
    { label: 'Random Sized BBox Safe Crop', value: 'Random Sized BBox Safe Crop' },
    { label: 'Random Sized Crop', value: 'Random Sized Crop' },
    { label: 'Channel Dropout', value: 'Channel Dropout' },
    { label: 'Coarse Dropout', value: 'Coarse Dropout' },
    { label: 'Cutout', value: 'Cutout' },
    { label: 'Grid Dropout', value: 'Grid Dropout' },
    { label: 'Mask Dropout', value: 'Mask Dropout' }
  ];

  return (
    <CustomDropdownField
      dropdownData={cropBlursList}
      {...props}
    />
  )

}

export default CustomCropBlursField