import { TextField, TextFieldProps } from '@mui/material'

function CustomTextField(props: TextFieldProps) {
  return (
    <TextField
      type='text'
      autoComplete='off'
      {...props}
    />
  )
}

export default CustomTextField