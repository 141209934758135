import { useContext, useState } from 'react'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { Box, Button, Dialog, DialogActions } from '@mui/material'
import ProjectContext from '@context/Project/projectContext';
import { getActionAfterSubmit } from '@components/shared-ui-components/ProjectForm/helper';
import { getStepsProgressbarApiEndpoint } from '@components/shared-ui-components/DynamicForm/helper';
import StepsEventsProgressbar from '@components/shared-ui-components/StepsEventsProgressbar';
import { performRelevanceDataSelectionTargetData } from '@services/dashboardService';
import { useParams } from 'react-router-dom';


function OptionsUploadUnLablledData() {

  const projectContextInfo = useContext(ProjectContext)
  const [files, setFiles] = useState<File[]>([]);

  const [dialog, setDialog] = useState(false)
  const [apiEndpoint, setApiEndpoint] = useState('')
  
  const { projectId, project_type } = useParams();

  const page = 'uploadUnlabelledSet'

  const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
    const actionAfterCall = getActionAfterSubmit(page, projectContextInfo);
    // Calling the api
    performRelevanceDataSelectionTargetData({...values, project_id: projectId, project_type})
      .then((res: any) => {
        // Reset the form after success
        actions.resetForm()
        // OVER HERE THE NEW ONE WE WILL HAVE TO HANDLE 

        // Enable the form
        actions.setSubmitting(false)
        actionAfterCall(res, values);

        console.log('values in process response ====>', values, res)
        const newApiEndpoint = `${getStepsProgressbarApiEndpoint(page)}/${res.data.process_id}`
        setApiEndpoint(newApiEndpoint)
        setDialog(true)
        // show a notification progress has been save
      }).catch((e: any) => {
        actions.setSubmitting(false);
      })
  }

  const formik: FormikProps<any> = useFormik({
    initialValues: { compressed_file: null },
    onSubmit: handleFormSubmit,
  })

  const { errors, handleSubmit: handleSub, isSubmitting, touched } = formik || {}

  const handleSetFiles = (fs: File[]) => {
    setFiles(fs);
    formik.setFieldValue('compressed_file', fs[0])
  }

  const handleUrl = (url: string) => {
    files.length > 0 && setFiles([]);
    formik.setFieldValue('compressed_file', url)
  }

  const closeProgressBar = () => {
    setDialog(false)
    setApiEndpoint('')
  }

  return (
    <>
      <h4>Upload Target Data</h4>
      <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
        <Box>
          <CustomFileUpload files={files} setFiles={handleSetFiles} />
          <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />
          <input id="file" name="compressed_file" type="file" hidden />
          <h4>Or</h4>
          <CustomTextField
            placeholder="Input File link"
            sx={{ mb: 2 }}
            fullWidth
            name="compressed_file"
            value={files.length === 0 ? formik.values.compressed_file : ''}
            onChange={(e) => handleUrl(e.target.value)}
          />
          <Box sx={{ mb: 2 }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{ mt: 1, mr: 1 }}
              >
                {`Upload`}
              </Button>

            </div>
          </Box>
        </Box>
      </form>

      {dialog && apiEndpoint !== '' && <Dialog open={dialog} fullWidth={true} onClose={() => closeProgressBar()}>
        <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
          <h4>Check Live Status</h4>
          <StepsEventsProgressbar apiEndpoint={apiEndpoint} closeProgressBar={closeProgressBar} />
        </Box>
        <DialogActions>
          <Button variant='contained' onClick={() => closeProgressBar()} sx={{ mt: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>}
    </>
  )
}

export default OptionsUploadUnLablledData