import {
  type MRT_ColumnDef,
} from 'material-react-table';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
//custom ui
import ProjectForm from '@components/shared-ui-components/ProjectForm';

export const steps = [
  {
    label: 'Step 1 - Create Project',
    form: 'createProject'
  },
  {
    label: 'Step 2 - Upload Unlabelled Data',
    form: 'uploadUnlabelledSet'
  },
  {
    label: 'Step 3 - Upload Labelled Data',
    form: 'uploadLabelledSet'
  }
];

export type Project = {
  id: string;
  projectName: string;
  projectType: string;
  created_date: string;
  updated_date: string;
};

interface IVerticalLinearStepper {
  onClose: any,
}

export function VerticalLinearStepper({ onClose }: IVerticalLinearStepper) {

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getStepContent = (index: number) => {
    return ProjectForm({ page: steps[index].form, activeStepIndex: activeStep, handleNext: handleNext, handleAction: () => { } });
  }

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent
              style={{ marginTop: '12px' }}
            >
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - New project Created !</Typography>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant='contained'
              onClick={onClose} sx={{ mt: 1 }}>
              Close
            </Button>

          </Box>
        </Paper>
      )}
    </Box>
  );
}

interface CreateModalProps {
  columns: MRT_ColumnDef<Project>[];
  onClose: () => void;
  onSubmit: (values: Project) => void;
  open: boolean;
}

const CreateNewAccountModal = ({
  open,
  onClose,
}: CreateModalProps) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">
        Create New Project
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <VerticalLinearStepper onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewAccountModal;