import { Outlet } from "react-router-dom";
import Appbar from "./appbar";
import { Box } from "@mui/material";

const MainLayout = () => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <Appbar />
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: { xs: '10px', sm: '14px 25px' }, marginTop: '10px' }}>
                    <Outlet />
                </Box>
            </div>
        </>

    )
}


export default MainLayout

