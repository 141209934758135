import { SelectProps } from "@mui/material";
import CustomDropdownField from "../CustomDropdownField";

interface CustomStrategyFieldProps extends SelectProps {
  formik: any;
}

function CustomStrategyField(props: CustomStrategyFieldProps) {
  const strategyList = [
    {
      label: "Least Confidence Sampling Dropout",
      value: "LeastConfidenceSamplingDropout",
    },
    { label: "Margin Sampling Dropout", value: "MarginSamplingDropout" },
    { label: "Grad Match Active", value: "GradMatchActive" },
    { label: "K-Means Sampling", value: "KMeansSampling" },
    { label: "Batch BALD Dropout", value: "BatchBALDDropout" },
    { label: "Entropy Sampling Dropout", value: "EntropySamplingDropout" },
    { label: "BADGE", value: "BADGE" },
    { label: "Adversarial BIM", value: "AdversarialBIM" },
    { label: "BALD Dropout", value: "BALDDropout" },
    { label: "Margin Sampling", value: "MarginSampling" },
    { label: "Core Set", value: "CoreSet" },
    { label: "Least Confidence Sampling", value: "LeastConfidenceSampling" },
    { label: "FASS", value: "FASS" },
    { label: "Random Sampling", value: "RandomSampling" },
    { label: "Entropy Sampling", value: "EntropySampling" },
    { label: "Submodular Sampling", value: "SubmodularSampling" },
    { label: "Adversarial Deep Fool", value: "AdversarialDeepFool" },
    { label: "GLISTER", value: "GLISTER" },
    { label: "FL1MI", value: "FL1MI" },
    { label: "GCMI", value: "GCMI" },
    { label: "FL2MI", value: "FL2MI" },
    { label: "Log Det MI", value: "LogDetMI" },
    { label: "COM", value: "COM" },
  ];

  return <CustomDropdownField dropdownData={strategyList} {...props} />;
}

export default CustomStrategyField;
