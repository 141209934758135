import { useMemo, useState } from "react";

import LoaderContext, { LoaderDetailsType } from "./loaderContext";

const LoaderProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const InitialLoaderDetailState = {
    loginLoader: false,
  };

  const [loaderState, loaderSetState] = useState<LoaderDetailsType>(
    InitialLoaderDetailState
  );

  const stateValue = useMemo(
    () => [{ loaderState, loaderSetState }],
    [loaderState, loaderSetState]
  );

  return (
    <LoaderContext.Provider value={stateValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
