import styled from "styled-components";

import { Typography } from "@mui/material";
import Colors from "@constants/Colors";

export const CustomTypographyH2 = styled(Typography)`
  &.MuiTypography-root {
    font-family: Ringside Regular-Bold;
    font-style: normal;
    font-weight: 700;
    color: ${Colors.neutralblack};
    font-size: 36px;
    line-height: 36px;
  }
`;

export const CustomTypographyH3 = styled(Typography)`
  &.MuiTypography-root {
    font-family: Ringside Regular-Bold;
    font-style: normal;
    font-weight: 600;
    color: ${Colors.neutralblack};
    font-size: 24px;
    line-height: 30px;
  }
`;

export const CustomTypographyH4 = styled(Typography)`
  &.MuiTypography-root {
    font-family: Ringside Regular-Bold;
    font-style: normal;
    font-weight: 400;
    color: ${Colors.neutralblack};
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CustomTypographyH5 = styled(Typography)`
  &.MuiTypography-root {
    font-family: Ringside Regular-Bold;
    font-style: normal;
    font-weight: 400;
    color: ${Colors.neutralblack};
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CustomTypographyH6 = styled(Typography)`
  &.MuiTypography-root {
    font-family: Ringside Regular-Book;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${Colors.neutral_gray_500};
  }
`;
