import { Button, CircularProgress } from '@mui/material'

interface ISubmitButton {
    btnName?: string,
    btnAction?: any,
    isSubmitting: boolean,
}

CustomSubmitButton.defaultProps = {
    btnName: 'Start',
    isSubmittin: false,
}

function CustomSubmitButton(props: ISubmitButton) {
    const { btnName, isSubmitting } = props;
    return (
        <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">

            {isSubmitting
                ? <>
                    <CircularProgress color='primary' size={20} />
                    <p style={{ margin: '0px 10px' }}>Loading...</p>
                </>
                : btnName
            }
        </Button>


    )
}

export default CustomSubmitButton;