import { userApiEndPoint } from "@services/apiEndpoints";
import services from "@services/index";
import { IRegister, ILogin } from "./types";

export const registerService = async (data: IRegister) => {
  try {
    const res = await services.post(userApiEndPoint.register, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const loginService = async (data: ILogin) => {
  try {
    const res: any = await services.post(userApiEndPoint.login, data);
    return res;
  } catch (error) {
    throw error;
  }
};


export const authService = async () => {
  try {
    const res = (await services.get('/user')) as { data: any };
    return { user: res.data };
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const res = await services.post('/forgot-password/send', { email });
    return res;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (
  new_password: string,
  password_verify_string: string,
  confirm_password: string
) => {
  try {
    const res = await services.post('/forgot-password/update', {
      new_password,
      password_verify_string,
      confirm_password
    });
    return res;
  } catch (error) {
    throw error;
  }
};
