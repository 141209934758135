import MultipleTable from '@components/shared-ui-components/MultipleTable'
import { Dialog, Box, DialogActions, Button } from '@mui/material'

interface IMetaDataLogsModal {
  projectId?: string,
  imageId?: string,
  showMetadataModal: boolean,
  closeMetadataModal: () => void
}

function MetaDataLogsModal({ projectId, imageId, showMetadataModal, closeMetadataModal }: IMetaDataLogsModal) {

  const apiEndpoint = `/data/${projectId}/image/metadata/${imageId}` // over here is the api 
  return (
    <Dialog open={showMetadataModal} fullWidth={true} maxWidth={false}>
      <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
        <h4 style={{ marginBottom: 0, textTransform: 'capitalize' }}>{imageId}</h4>
        <MultipleTable apiEndpoint={apiEndpoint} />
      </Box>
      <DialogActions>
        <Button variant='contained' onClick={closeMetadataModal} sx={{ mt: 2 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MetaDataLogsModal