import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'

interface CustomSelectEvaluationFieldProps extends SelectProps {
  formik: any
}

function CustomSelectEvaluationField(props: CustomSelectEvaluationFieldProps) {

  const evaluationList = [
    { value: 'groundTruthEvaluation', label: 'Ground Truth Evaluation' },
    { value: 'automatedEvaluation', label: 'Automated Evaluation' }
  ]

  return (
    <CustomDropdownField
      dropdownData={evaluationList}
      {...props}
    />
  )
}

export default CustomSelectEvaluationField