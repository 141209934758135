// This folder will consists of common logic required across projects
import services from "@services/index";

export const removeQueryParams = () => {
  window.history.pushState({}, document.title, window.location.pathname);
};

export const getQueryParams = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getApiResponse = async (path: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await services.get(`/${path}`);
    if (res.status === 200) return { data: res?.data.data, error: null };
    return {
      data: null,
      error: res?.data?.data.error || "Something went wrong!",
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return {
      data: null,
      error: err?.error?.message || "Something went wrong!",
    };
  }
};

export const convertToBaseModel = (str: string) => {
  return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


export const getProjectType = () => {
  return localStorage
  .getItem("projectType")
  ?.toLowerCase()
  .replace(/ /g, "_");
}