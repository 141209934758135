import UploadDataPage from './UploadDataPage'

function RelevantDataSelectionPage() {
  return (
    <>
      <UploadDataPage/>
    </>
  )
}

export default RelevantDataSelectionPage