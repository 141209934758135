import DynamicForm from '@components/shared-ui-components/DynamicForm'
import { Paper } from '@mui/material'

function InferencePage() {
  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0px'
      }}
    >
      <h4>Please fill up the Inference form</h4>
      <DynamicForm page={'inference'} />
    </Paper>
  )
}

export default InferencePage