import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper';
import { projectFormValidationSchema } from '@components/shared-ui-components/ProjectForm/helper';
import ProjectContext from '@context/Project/projectContext';
import { Paper } from '@mui/material'
import CreateLabelsForm from '@pages/ProjectsPage/steps/createLabelStep'
import { createLabels, deleteLabels, updateLabelsV2 } from '@services/projectService';
import { FormikHelpers, useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom';


interface ILabelsNew {
  classes: string[]
  keys: string[]
  colors: string[]
}

const initialValuesILabel: ILabelsNew = {
  classes: [],
  keys: [],
  colors: []
}

function LabelsPage() {

  const { projectId } = useParams()

  const projectContextInfo = useContext(ProjectContext)

  const [tempDeletedLabels, setTempDeletedLabels] = useState<string[]>([])

  const [tempAddedLabels, setTempAddedLabels] = useState<ILabelsNew>(initialValuesILabel)

  const [tempUpdateLabels, setTempUpdateLabels] = useState<ILabelsNew>(initialValuesILabel)

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {

    console.log('values in the labels page', values, tempAddedLabels, tempDeletedLabels)

    // const data: any = {
    //   projectId,
    //   labels: values.labels
    // }

    const addedData: any = {
      projectId,
      labels: tempAddedLabels
    }

    const updateData: any = {
      projectId,
      labels: tempUpdateLabels
    }

    const deleteData: any = {
      projectId,
      labels: tempDeletedLabels
    }

    actions.setSubmitting(true)



    if (tempAddedLabels.classes.length > 0 || tempDeletedLabels.length > 0 || tempUpdateLabels.classes.length > 0) {
      if (tempAddedLabels.classes.length > 0) {
        createLabels(addedData, projectContextInfo)
          .then(resp => {
            actions.setSubmitting(false)
            setTempAddedLabels(initialValuesILabel)
          })
          .catch(e => {
            showErrorSnackbar(e.error.message)
            actions.setSubmitting(false)
          })
      }

      if (tempUpdateLabels.classes.length > 0) {
        updateLabelsV2(updateData, projectContextInfo)
          .then(resp => {
            actions.setSubmitting(false)
            // setTempUpdateLabels(initialValuesILabel)
          })
          .catch(e => {
            showErrorSnackbar(e.error.message)
            actions.setSubmitting(false)
          })
      }

      if (tempDeletedLabels.length > 0) {
        deleteLabels(deleteData, projectContextInfo)
          .then(resp => {
            actions.setSubmitting(false)
            setTempDeletedLabels([])
          })
          .catch(e => {
            showErrorSnackbar(e.error.message)
            actions.setSubmitting(false)
          })
      }

      showSuccessSnacbar('Saved Labels')
    }

    // if (tempAddedLabels.classes.length === 0 && tempDeletedLabels.length === 0) {
    //   createLabels(data, projectContextInfo)
    //     .then(resp => {
    //       actions.setSubmitting(false)
    //       showSuccessSnacbar('Saved Labels')
    //     })
    //     .catch(e => {
    //       showErrorSnackbar(e.error.message)
    //       actions.setSubmitting(false)
    //     })
    // }

  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: projectFormValidationSchema['createLabels'],
    onSubmit: handleSubmit,
  })

  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px !important',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>Add / Edit Labels</h4>
      <CreateLabelsForm formik={formik} setTempAddedLabels={setTempAddedLabels} setTempDeletedLabels={setTempDeletedLabels} tempAddedLabels={tempAddedLabels} tempDeletedLabels={tempDeletedLabels} tempUpdateLabels={tempUpdateLabels} setTempUpdateLabels={setTempUpdateLabels} />
    </Paper>
  )
}

export default LabelsPage