import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'
import React, { useEffect, useState } from 'react'
import { getLabels } from '@services/projectService'
import { useParams } from 'react-router-dom'
import { showErrorSnackbar } from '@components/shared-layouts/Snackbar/helper'

interface CustomLabelsFieldProps extends SelectProps {
  formik: any
}

function CustomLabelsField(props: CustomLabelsFieldProps) {

  const { projectId } = useParams()
  const [labelsList, setLabelsList] = useState([])

  const formatLabels = (labels: any) => {
    let _labels: any = []
    if (labels?.length > 0) {
      labels?.forEach((it: any, index: any) => {
        let item = {
          label: it,
          value: it
        }
        _labels.push(item);
      });
    }
    setLabelsList(_labels);
  }

  useEffect(() => {
    getLabels(projectId)
      .then((resp: any) => {
        formatLabels(resp?.labels)
      })
      .catch(e => showErrorSnackbar('Could not fetch labels'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('label field props', props)

  console.log('labels list', labelsList)

  return (
    <>
      <CustomDropdownField
        dropdownData={labelsList}
        {...props}
      />
    </>

  )
}

export default React.memo(CustomLabelsField)