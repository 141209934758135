import { useEffect, useState } from 'react';

// material-ui
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';

// custom ui
import { ErrorHelper as ErrorHelperWrapper, CustomEmailField, CustomPasswordField, CustomTextField } from '@components/shared-ui-components/CustomFields';
import { CustomSubmitButton } from '@components/shared-ui-components/Buttons'

// project import
import { strengthColor, strengthIndicator } from 'utils/passwordStrength';

const AuthRegister = (formik: any) => {
  const [level, setLevel] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword('');
  }, []);

  const { errors, handleBlur, handleChange, handleSubmit: handleSub, isSubmitting, touched, values } = formik || {}
  return (
    <form noValidate onSubmit={handleSub}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <CustomTextField
            fullWidth={true}
            id="username-register"
            value={values.username}
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.username && errors.username)}
            label="Username"
            placeholder="Enter Username"
          />
          <ErrorHelperWrapper isError={!!(touched.username && errors.username)} errorMsg={errors.username} helperId='username-register' />
        </Grid>
        <Grid item xs={12}>
          <CustomEmailField
            id="email-login"
            value={values.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.email && errors.email)}
            label="Email Address"
          />
          <ErrorHelperWrapper isError={!!(touched.email && errors.email)} errorMsg={errors.email} helperId='email-register' />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth={true}
            id="wandb-token-register"
            value={values.wandb_token}
            name="wandb_token"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.wandb_token && errors.wandb_token)}
            label="WandB Token"
            placeholder="Enter WandB Token"
          />
          <ErrorHelperWrapper isError={!!(touched.wandb_token && errors.wandb_token)} errorMsg={errors.wandb_token} helperId='wandb-token-register' />
        </Grid>
        <Grid item xs={12}>
          <CustomPasswordField
            error={Boolean(touched.password && errors.password)}
            id="-password-login"
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            name="password"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              changePassword(e.target.value);
            }}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          />
          <ErrorHelperWrapper isError={!!(touched.password && errors.password)} errorMsg={errors.password} helperId='password-login' />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box sx={{ bgcolor: level?.color, width: 120, height: 8, borderRadius: '7px' }} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" fontSize="0.75rem">
                  {level?.label}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        {errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{errors.submit.toString()}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomSubmitButton btnName="Create Account" isSubmitting={isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthRegister;
